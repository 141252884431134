import Vue from "vue";

export function toCurrency(value) {
  if (typeof value !== "number") {
    return value;
  }

  const formatter = new Intl.NumberFormat("jp-JP", {
    style: "currency",
    currency: "JPY",
    minimumFractionDigits: 0
  });

  return formatter.format(value);
}

export function formatThousands(value) {
  if (typeof value !== "number") {
    return value;
  }

  return value.toLocaleString("jp");
}

const filters = { toCurrency, formatThousands };

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
