import CompanyIcon from '@/components/icons/CompanyIcon'
import BellIcon from '@/components/icons/BellIcon'
import GearIcon from '@/components/icons/GearIcon'
import BusinessIcon from '@/components/icons/BusinessIcon'
import CustomChevronRight from '@/components/icons/CustomChevronRight'
import InfoIcon from '@/components/icons/InfoIcon'
import PerformanceIcon from '@/components/icons/PerformanceIcon'
import SalesAndCostIcon from '@/components/icons/SalesAndCostIcon'
import BankIcon from '@/components/icons/BankIcon'
import GoBackIcon from '@/components/icons/GoBackIcon'
import EditIcon from '@/components/icons/EditIcon'
import UsersIcon from '@/components/icons/UsersIcon'
import AdminUsersIcon from '@/components/icons/AdminUsersIcon'
import CustomChevronUp from '@/components/icons/CustomChevronUp'
import CustomChevronDown from '@/components/icons/CustomChevronDown'
import CheckIcon from '@/components/icons/CheckIcon'
import CrossIcon from '@/components/icons/CrossIcon'
import AddUserIcon from '@/components/icons/AddUserIcon'
import AddAdminUserIcon from '@/components/icons/AddAdminUserIcon'
import CheckboxOnIcon from '@/components/icons/CheckboxOnIcon'
import CheckboxOffIcon from '@/components/icons/CheckboxOffIcon'
import TripleDotsIcon from '@/components/icons/TripleDotsIcon'
import AccountingIcon from '@/components/icons/AccountingIcon'
import ClearIcon from '@/components/icons/ClearIcon'
import RightArrowIcon from '@/components/icons/RightArrowIcon'

export default {
  company: {
    component: CompanyIcon
  },
  bell: {
    component: BellIcon
  },
  gear: {
    component: GearIcon
  },
  business: {
    component: BusinessIcon
  },
  info: {
    component: InfoIcon
  },
  accounting: {
    component: AccountingIcon
  },
  performance: {
    component: PerformanceIcon
  },
  sales: {
    component: SalesAndCostIcon
  },
  bank: {
    component: BankIcon
  },
  goBack: {
    component: GoBackIcon
  },
  edit: {
    component: EditIcon
  },
  users: {
    component: UsersIcon
  },
  adminUsers: {
    component: AdminUsersIcon
  },
  addUser: {
    component: AddUserIcon
  },
  addAdminUser: {
    component: AddAdminUserIcon
  },
  check: {
    component: CheckIcon
  },
  customCheckboxOn: {
    component: CheckboxOnIcon
  },
  customCheckboxOff: {
    component: CheckboxOffIcon
  },
  cross: {
    component: CrossIcon
  },
  customChevronRight: {
    component: CustomChevronRight
  },
  customChevronUp: {
    component: CustomChevronUp
  },
  customChevronDown: {
    component: CustomChevronDown
  },
  tripleDots: {
    component: TripleDotsIcon
  },
  clear: {
    component: ClearIcon
  },
  rightArrow: {
    component: RightArrowIcon
  }
}
