export const COMPANY_REQUEST = 'COMPANY_REQUEST'
export const COMPANY_GET = 'COMPANY_GET'
export const COMPANY_GET_SUCCESS = 'COMPANY_GET_SUCCESS'
export const COMPANY_GET_ERROR = 'COMPANY_GET_ERROR'
export const COMPANY_UPDATE = 'COMPANY_UPDATE'
export const COMPANY_UPDATE_SUCCESS = 'COMPANY_UPDATE_SUCCESS'
export const COMPANY_UPDATE_ERROR = 'COMPANY_UPDATE_ERROR'
export const COMPANY_ACCOUNT_ITEMS_GET = 'COMPANY_ACCOUNT_ITEMS_GET'
export const COMPANY_ACCOUNT_ITEMS_GET_SUCCESS =
  'COMPANY_ACCOUNT_ITEMS_GET_SUCCESS'
export const COMPANY_ACCOUNT_ITEMS_GET_ERROR = 'COMPANY_ACCOUNT_ITEMS_GET_ERROR'
export const COMPANY_ACCOUNT_ITEMS_UPDATE = 'COMPANY_ACCOUNT_ITEMS_UPDATE'
export const COMPANY_ACCOUNT_ITEMS_UPDATE_SUCCESS =
  'COMPANY_ACCOUNT_ITEMS_UPDATE_SUCCESS'
export const COMPANY_ACCOUNT_ITEMS_UPDATE_ERROR =
  'COMPANY_ACCOUNT_ITEMS_UPDATE_ERROR'
export const COMPANY_USERS_GET = 'COMPANY_USERS_GET'
export const COMPANY_USERS_GET_SUCCESS = 'COMPANY_USERS_GET_SUCCESS'
export const COMPANY_USERS_GET_ERROR = 'COMPANY_USERS_GET_ERROR'
export const COMPANY_SALES_GET = 'COMPANY_SALES_GET'
export const COMPANY_SALES_GET_SUCCESS = 'COMPANY_SALES_GET_SUCCESS'
export const COMPANY_SALES_GET_ERROR = 'COMPANY_SALES_GET_ERROR'
export const COMPANY_COSTS_GET = 'COMPANY_COSTS_GET'
export const COMPANY_COSTS_GET_SUCCESS = 'COMPANY_COSTS_GET_SUCCESS'
export const COMPANY_COSTS_GET_ERROR = 'COMPANY_COSTS_GET_ERROR'
export const COMPANY_BUSINESS_YEARS_GET = 'COMPANY_BUSINESS_YEARS_GET'
export const COMPANY_BUSINESS_YEARS_GET_SUCCESS =
  'COMPANY_BUSINESS_YEARS_GET_SUCCESS'
export const COMPANY_BUSINESS_YEARS_GET_ERROR =
  'COMPANY_BUSINESS_YEARS_GET_ERROR'
export const COMPANY_PERFORMANCE_RESET = 'COMPANY_PERFORMANCE_RESET'
export const COMPANY_PERFORMANCE_GET = 'COMPANY_PERFORMANCE_GET'
export const COMPANY_PERFORMANCE_GET_SUCCESS = 'COMPANY_PERFORMANCE_GET_SUCCESS'
export const COMPANY_PERFORMANCE_GET_ERROR = 'COMPANY_PERFORMANCE_GET_ERROR'
export const COMPANY_BANK_BALANCE_GET = 'COMPANY_BANK_BALANCE_GET'
export const COMPANY_BANK_BALANCE_GET_SUCCESS =
  'COMPANY_BANK_BALANCE_GET_SUCCESS'
export const COMPANY_BANK_BALANCE_GET_ERROR = 'COMPANY_BANK_BALANCE_GET_ERROR'
export const COMPANY_SALES_GOAL_GET = 'COMPANY_SALES_GOAL_GET'
export const COMPANY_SALES_GOAL_GET_SUCCESS = 'COMPANY_SALES_GOAL_GET_SUCCESS'
export const COMPANY_SALES_GOAL_GET_ERROR = 'COMPANY_SALES_GOAL_GET_ERROR'
export const COMPANY_SALES_GOAL_CREATE = 'COMPANY_SALES_GOAL_CREATE'
export const COMPANY_SALES_GOAL_CREATE_SUCCESS =
  'COMPANY_SALES_GOAL_CREATE_SUCCESS'
export const COMPANY_SALES_GOAL_CREATE_ERROR = 'COMPANY_SALES_GOAL_CREATE_ERROR'
export const COMPANY_SALES_GOAL_LIST_GET = 'COMPANY_SALES_GOAL_LIST_GET'
export const COMPANY_SALES_GOAL_LIST_GET_SUCCESS =
  'COMPANY_SALES_GOAL_LIST_GET_SUCCESS'
export const COMPANY_SALES_GOAL_LIST_GET_ERROR =
  'COMPANY_SALES_GOAL_LIST_GET_ERROR'
export const COMPANY_SALES_GOAL_DELETE = 'COMPANY_SALES_GOAL_DELETE'
export const COMPANY_SALES_GOAL_DELETE_SUCCESS =
  'COMPANY_SALES_GOAL_DELETE_SUCCESS'
export const COMPANY_SALES_GOAL_DELETE_ERROR = 'COMPANY_SALES_GOAL_DELETE_ERROR'
export const COMPANY_FL_GOAL_GET = 'COMPANY_FL_GOAL_GET'
export const COMPANY_FL_GOAL_GET_SUCCESS = 'COMPANY_FL_GOAL_GET_SUCCESS'
export const COMPANY_FL_GOAL_GET_ERROR = 'COMPANY_FL_GOAL_GET_ERROR'
export const COMPANY_FL_GOAL_CREATE = 'COMPANY_FL_GOAL_CREATE'
export const COMPANY_FL_GOAL_CREATE_SUCCESS = 'COMPANY_FL_GOAL_CREATE_SUCCESS'
export const COMPANY_FL_GOAL_CREATE_ERROR = 'COMPANY_FL_GOAL_CREATE_ERROR'
export const COMPANY_FL_GOAL_LIST_GET = 'COMPANY_FL_GOAL_LIST_GET'
export const COMPANY_FL_GOAL_LIST_GET_SUCCESS =
  'COMPANY_FL_GOAL_LIST_GET_SUCCESS'
export const COMPANY_FL_GOAL_LIST_GET_ERROR = 'COMPANY_FL_GOAL_LIST_GET_ERROR'
export const COMPANY_FL_GOAL_DELETE = 'COMPANY_FL_GOAL_DELETE'
export const COMPANY_FL_GOAL_DELETE_SUCCESS = 'COMPANY_FL_GOAL_DELETE_SUCCESS'
export const COMPANY_FL_GOAL_DELETE_ERROR = 'COMPANY_FL_GOAL_DELETE_ERROR'
