<template>
  <svg
    version="1.1"
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    enable-background="new 0 0 64 64"
    xml:space="preserve"
  >
    <g>
      <polygon fill="none" points="42.434,21 49.051,21 55.029,16 47.789,16 	" />
      <polygon fill="none" points="22.702,16 14.911,16 8.932,21 17.421,21 	" />
      <path
        fill="currentColor"
        d="M59.229,39.18L49.032,48H38v-6h11.777c0.359,0,0.71-0.131,0.981-0.365l10.761-9.308
		c0.626-0.542,0.695-1.489,0.153-2.116c-0.542-0.626-1.489-0.695-2.116-0.153L49.219,39H38v-6h11.777c0.359,0,0.71-0.13,0.981-0.366
		l10.761-9.308c0.626-0.542,0.695-1.489,0.153-2.116c-0.542-0.626-1.489-0.695-2.116-0.153L49.219,30H38v-4.861L38.148,25h11.629
		c0.471,0,0.922-0.164,1.283-0.466l10.761-9c0.644-0.539,0.883-1.428,0.597-2.217S61.378,12,60.538,12H47H27.723H14.185
		c-0.471,0-0.922,0.164-1.283,0.466l-10.762,9c-0.644,0.539-0.883,1.428-0.597,2.217S2.583,25,3.423,25H15v5H3
		c-0.829,0-1.5,0.671-1.5,1.5S2.171,33,3,33h12v6H3c-0.829,0-1.5,0.672-1.5,1.5S2.171,42,3,42h12v6H3c-1.104,0-2,0.896-2,2
		s0.896,2,2,2h14h19h13.777c0.479,0,0.946-0.174,1.309-0.487l10.761-9.308c0.835-0.723,0.927-1.985,0.204-2.821
		C61.328,38.549,60.065,38.457,59.229,39.18z M19,25.014L28.52,16h13.407l-7.292,6.808C34.229,23.188,34,23.713,34,24.27V48H19
		V25.014z M49.051,21h-6.617l5.355-5h7.24L49.051,21z M14.911,16h7.792l-5.281,5H8.932L14.911,16z"
      />
      <path
        fill="#FFFFFF"
        d="M34,24.27c0-0.557,0.229-1.082,0.635-1.462L41.927,16H28.52L19,25.014V48h15V24.27z M30,43h-1v1
		c0,1.104-0.896,2-2,2s-2-0.896-2-2v-1h-2c-1.104,0-2-0.896-2-2s0.896-2,2-2h2v-1h-3c-1.104,0-2-0.896-2-2s0.896-2,2-2h2.906
		l-3.924-3.096c-0.867-0.684-1.016-1.942-0.332-2.809s1.942-1.016,2.809-0.332l3.239,2.556l3.211-2.551
		c0.865-0.687,2.123-0.543,2.81,0.322s0.543,2.123-0.322,2.81L29,33.6V34h2c1.104,0,2,0.896,2,2s-0.896,2-2,2h-2v1h1
		c1.104,0,2,0.896,2,2S31.104,43,30,43z"
      />
      <path
        fill="currentColor"
        d="M32,41c0-1.104-0.896-2-2-2h-1v-1h2c1.104,0,2-0.896,2-2s-0.896-2-2-2h-2v-0.4l3.398-2.7
		c0.865-0.687,1.009-1.945,0.322-2.81s-1.945-1.009-2.81-0.322l-3.211,2.551l-3.239-2.556c-0.867-0.684-2.125-0.536-2.809,0.332
		s-0.536,2.125,0.332,2.809L24.906,34H22c-1.104,0-2,0.896-2,2s0.896,2,2,2h3v1h-2c-1.104,0-2,0.896-2,2s0.896,2,2,2h2v1
		c0,1.104,0.896,2,2,2s2-0.896,2-2v-1h1C31.104,43,32,42.104,32,41z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'BankIcon'
}
</script>

<style scoped></style>
