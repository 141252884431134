<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.71316 10.8843C5.24023 10.8558 3.23857 8.82284 3.23926 6.34028C3.23995 3.7845 5.32857 1.74744 7.89604 1.79728C10.3614 1.84575 12.3572 3.91409 12.3246 6.38703C12.2909 8.91566 10.2425 10.9135 7.71316 10.8843ZM10.4979 6.31244C10.4807 4.81231 9.27138 3.61469 7.77641 3.61744C6.28041 3.62019 5.07557 4.82366 5.06595 6.32378C5.05598 7.86069 6.2976 9.08994 7.83313 9.06313C9.32707 9.03734 10.5147 7.81153 10.4979 6.31244Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.80489 12.7C8.83236 12.7 9.86052 12.6808 10.888 12.7034C13.5565 12.7612 15.4317 14.6271 15.4997 17.2812C15.5145 17.8552 15.51 18.43 15.4977 19.004C15.4856 19.5843 15.1395 19.9315 14.597 19.9328C14.0553 19.9346 13.705 19.5925 13.6909 19.0102C13.6772 18.4664 13.6954 17.9219 13.6827 17.3778C13.6425 15.6587 12.54 14.537 10.8392 14.5219C8.8138 14.504 6.78808 14.5061 4.76305 14.5208C3.00545 14.5339 1.90924 15.6501 1.87933 17.4276C1.87108 17.9412 1.8783 18.4551 1.8752 18.9686C1.87142 19.5478 1.54589 19.9157 1.00517 19.9315C0.413579 19.949 0.0574539 19.5764 0.0639852 18.9542C0.0729227 18.1089 0.00623515 17.2471 0.14511 16.4214C0.50261 14.2995 2.27017 12.8018 4.4492 12.7144C5.56605 12.6701 6.68564 12.7065 7.8042 12.7065C7.80489 12.7045 7.80489 12.7021 7.80489 12.7Z" fill="currentColor"/>
    <path d="M21.0763 9.28133H19.2499V7.3113C19.2499 6.82558 18.7048 6.43164 18.2187 6.43164C17.7326 6.43164 17.1874 6.82558 17.1874 7.3113V9.28133H15.447C14.961 9.28133 14.5674 9.65498 14.5674 10.1407C14.5674 10.6264 14.961 11.0001 15.447 11.0001H17.1874V12.9405C17.1874 13.4263 17.7326 13.8202 18.2187 13.8202C18.7048 13.8202 19.2499 13.4263 19.2499 12.9405V11.0001H21.0763C21.562 11.0001 21.9559 10.6264 21.9559 10.1407C21.9559 9.65498 21.5624 9.28133 21.0763 9.28133Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
name: "AddUserIcon"
}
</script>

<style scoped>

</style>