import { render, staticRenderFns } from "./SalesAndCostIcon.vue?vue&type=template&id=c37688ba&scoped=true&"
import script from "./SalesAndCostIcon.vue?vue&type=script&lang=js&"
export * from "./SalesAndCostIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c37688ba",
  null
  
)

export default component.exports