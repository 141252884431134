<template>
  <v-app>
    <v-overlay z-index="7" :value="apiProcessing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <router-view />
    <v-snackbar v-model="snackbar" timeout="4000">
      {{ alertText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      apiProcessing: false,
      snackbar: false,
      alertText: null
    }
  },
  created() {
    this.apiProcessing = this.$store.state.apiProcessing
  },
  watch: {
    '$store.state.apiProcessing': function() {
      this.apiProcessing = this.$store.state.apiProcessing
    },
    '$store.state.alert': function() {
      this.snackbar = this.$store.state.alert.show
      this.alertText = this.$store.state.alert.text
    }
  }
}
</script>
