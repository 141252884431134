import axios from 'axios'
import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  FREEE_CHECK_CODE,
  FREEE_SET_CODE,
  AUTH_FORGOT,
  AUTH_RESET
} from '@/store/actions/auth'
import store from '@/store'
import router from '@/router/index.js'

function initialState() {
  return {
    token: '',
    status: '',
    user: null,
    isFreeeCodeAccess: false
  }
}

const state = {
  token: '',
  status: '',
  user: null,
  isFreeeCodeAccess: false
}

const getters = {
  token: state => state.token,
  isLoggedIn: state => !!state.token,
  isFreeeCodeAccess: state => state.isFreeeCodeAccess,
  authStatus: state => state.status,
  user: state => state.user
}

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit('AUTH_REQUEST')
      dispatch('API_PROCESSING', true, { root: true })
      axios({
        url: '/admin/auth/login',
        data: user,
        method: 'POST'
      })
        .then(resp => {
          dispatch('API_PROCESSING', false, { root: true })
          console.log(resp)
          if (resp.status >= 200 && resp.status <= 299) {
            const token = resp.data.data.access_token
            const user = resp.data.data.user
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
            commit('AUTH_SUCCESS', { token, user })
            dispatch('MASTER_ALL_GET')
          }
          resolve(resp)
        })
        .catch(err => {
          dispatch('API_PROCESSING', false, { root: true })
          commit('AUTH_ERROR')
          reject(err)
        })
    })
  },
  [AUTH_LOGOUT]: ({ commit, dispatch }) => {
    dispatch('API_PROCESSING', true, { root: true })
    return new Promise(resolve => {
      axios
        .post('/admin/auth/logout')
        .then(resp => {
          console.log(resp)
          if (resp.status >= 200 && resp.status <= 299) {
            console.log('xxx')
            dispatch('API_PROCESSING', false, { root: true })
            //commit('FREEE_CODE', false)
            commit('AUTH_LOGOUT')
          } else {
            dispatch('API_PROCESSING', false, { root: true })
          }
        })
        .catch(err => {
          console.log(err)
          dispatch('API_PROCESSING', false, { root: true })
        })
      resolve()
    })
  },
  [AUTH_FORGOT]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit('AUTH_FORGOT')
      dispatch('API_PROCESSING', true, { root: true })
      axios({
        url: '/admin/auth/forgot-password',
        data: user,
        method: 'POST'
      })
        .then(resp => {
          dispatch('API_PROCESSING', false, { root: true })
          if (resp.status >= 200 && resp.status <= 299) {
            console.log(resp)
          }
          resolve(resp)
        })
        .catch(err => {
          dispatch('API_PROCESSING', false, { root: true })
          commit('AUTH_ERROR')
          reject(err)
        })
    })
  },
  [AUTH_RESET]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit('AUTH_RESET')
      dispatch('API_PROCESSING', true, { root: true })
      axios({
        url: '/admin/auth/reset-password',
        data: user,
        method: 'POST'
      })
        .then(resp => {
          dispatch('API_PROCESSING', false, { root: true })
          if (resp.status >= 200 && resp.status <= 299) {
            console.log(resp)
          }
          resolve(resp)
        })
        .catch(err => {
          dispatch('API_PROCESSING', false, { root: true })
          commit('AUTH_ERROR')
          reject(err)
        })
    })
  },
  [FREEE_SET_CODE]: ({ commit, dispatch }, code) => {
    dispatch('API_PROCESSING', true, { root: true })
    return new Promise((resolve, reject) => {
      axios
        .post('/admin/setCode?code=' + code)
        .then(resp => {
          if (resp.data.success) {
            dispatch('API_PROCESSING', false, { root: true })
            commit('FREEE_CODE', true)
            resolve()
          }
        })
        .catch(err => {
          dispatch('API_PROCESSING', false, { root: true })
          reject(err)
        })
    })
  },
  [FREEE_CHECK_CODE]: ({ commit, dispatch }) => {
    dispatch('API_PROCESSING', true, { root: true })
    return new Promise((resolve, reject) => {
      axios
        .get('/admin/checkCode')
        .then(resp => {
          if (resp.data.success) {
            dispatch('API_PROCESSING', false, { root: true })
            commit('FREEE_CODE', true)
            resolve()
          }
          reject()
        })
        .catch(err => {
          dispatch('API_PROCESSING', false, { root: true })
          reject(err)
        })
    })
  }
}

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.token = params.token
    state.user = params.user
  },
  [AUTH_ERROR]: state => {
    state.status = 'error'
  },
  [AUTH_LOGOUT]: state => {
    state.token = ''
    router.push({ name: 'Login' }).catch(() => {})

    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
    window.localStorage.clear()
    state.status = ''
    state.user = null
  },
  FREEE_CODE(state, isFreeeCodeAccess) {
    state.isFreeeCodeAccess = isFreeeCodeAccess
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
