<template>
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66884 11.3221C4.78284 11.3221 2.89709 11.3186 1.01134 11.3251C0.617089 11.3266 0.241839 11.3094 0.0560891 10.8881C-0.130161 10.4661 0.187089 10.2516 0.428589 9.98764C1.31734 9.01589 1.66984 7.80939 1.84909 6.54639C1.93884 5.91239 1.96609 5.26789 1.99359 4.62689C2.10734 1.98589 4.13459 -0.0191126 6.68459 0.000137446C9.21884 0.0188874 11.2203 1.99539 11.3151 4.61214C11.3656 6.01489 11.4791 7.39739 12.0641 8.69964C12.2923 9.20764 12.5503 9.69339 12.9766 10.0686C13.2231 10.2849 13.4101 10.5354 13.2686 10.8799C13.1231 11.2344 12.8258 11.3279 12.4588 11.3261C10.5291 11.3174 8.59884 11.3221 6.66884 11.3221ZM11.1988 9.97414C10.7546 9.13614 10.4328 8.29689 10.2566 7.41064C10.0746 6.49539 10.0146 5.56814 9.98134 4.63564C9.91534 2.75589 8.48484 1.34339 6.67434 1.33389C4.84334 1.32414 3.39234 2.76139 3.33384 4.67089C3.29509 5.93764 3.19584 7.19389 2.79059 8.40264C2.61309 8.93139 2.37859 9.44114 2.16409 9.97389C5.18459 9.97414 8.15309 9.97414 11.1988 9.97414Z" fill="#625F6E"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.65533 14.6522C5.99783 14.6224 5.43283 14.3877 5.04008 13.8027C4.79683 13.4404 4.76358 13.0954 5.11483 12.8032C5.45333 12.5214 5.77808 12.6327 6.03933 12.9392C6.47658 13.4522 6.87558 13.4139 7.30033 12.9147C7.56233 12.6064 7.90383 12.5307 8.22958 12.8222C8.54408 13.1044 8.51408 13.4394 8.29583 13.7762C7.91483 14.3647 7.35458 14.6177 6.65533 14.6522Z" fill="#625F6E"/>
  </svg>
</template>

<script>
export default {
name: "BellIcon"
}
</script>

<style scoped>

</style>