<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#F7F7F7" stroke="#C4C4C4"/>
  </svg>
</template>

<script>
export default {
name: "CheckboxOffIcon"
}
</script>

<style scoped>

</style>