import axios from 'axios'
import {
  COMPANY_REQUEST,
  COMPANY_GET,
  COMPANY_GET_SUCCESS,
  COMPANY_GET_ERROR,
  COMPANY_UPDATE,
  COMPANY_UPDATE_SUCCESS,
  COMPANY_UPDATE_ERROR,
  COMPANY_ACCOUNT_ITEMS_GET,
  COMPANY_ACCOUNT_ITEMS_GET_SUCCESS,
  COMPANY_ACCOUNT_ITEMS_GET_ERROR,
  COMPANY_ACCOUNT_ITEMS_UPDATE,
  COMPANY_ACCOUNT_ITEMS_UPDATE_SUCCESS,
  COMPANY_ACCOUNT_ITEMS_UPDATE_ERROR,
  COMPANY_USERS_GET,
  COMPANY_USERS_GET_SUCCESS,
  COMPANY_USERS_GET_ERROR,
  COMPANY_SALES_GET,
  COMPANY_SALES_GET_SUCCESS,
  COMPANY_SALES_GET_ERROR,
  COMPANY_BUSINESS_YEARS_GET,
  COMPANY_BUSINESS_YEARS_GET_SUCCESS,
  COMPANY_BUSINESS_YEARS_GET_ERROR,
  COMPANY_PERFORMANCE_GET,
  COMPANY_PERFORMANCE_GET_SUCCESS,
  COMPANY_PERFORMANCE_GET_ERROR,
  COMPANY_PERFORMANCE_RESET,
  COMPANY_BANK_BALANCE_GET,
  COMPANY_BANK_BALANCE_GET_SUCCESS,
  COMPANY_BANK_BALANCE_GET_ERROR,
  COMPANY_SALES_GOAL_GET,
  COMPANY_SALES_GOAL_GET_SUCCESS,
  COMPANY_SALES_GOAL_GET_ERROR,
  COMPANY_SALES_GOAL_CREATE,
  COMPANY_SALES_GOAL_CREATE_SUCCESS,
  COMPANY_SALES_GOAL_CREATE_ERROR,
  COMPANY_SALES_GOAL_LIST_GET,
  COMPANY_SALES_GOAL_LIST_GET_SUCCESS,
  COMPANY_SALES_GOAL_LIST_GET_ERROR,
  COMPANY_SALES_GOAL_DELETE,
  COMPANY_SALES_GOAL_DELETE_SUCCESS,
  COMPANY_SALES_GOAL_DELETE_ERROR,
  COMPANY_FL_GOAL_GET,
  COMPANY_FL_GOAL_GET_SUCCESS,
  COMPANY_FL_GOAL_GET_ERROR,
  COMPANY_FL_GOAL_CREATE,
  COMPANY_FL_GOAL_CREATE_SUCCESS,
  COMPANY_FL_GOAL_CREATE_ERROR,
  COMPANY_FL_GOAL_LIST_GET,
  COMPANY_FL_GOAL_LIST_GET_SUCCESS,
  COMPANY_FL_GOAL_LIST_GET_ERROR,
  COMPANY_FL_GOAL_DELETE,
  COMPANY_FL_GOAL_DELETE_SUCCESS,
  COMPANY_FL_GOAL_DELETE_ERROR
} from '@/store/actions/company'
import store from '@/store'

function initialState() {
  return {
    status: '',
    company: {},
    account_items: [],
    users: [],
    sales: {},
    costs: {},
    current_business_year: {},
    business_years: [],
    today: '',
    performances: [],
    bank_balances: [],
    sales_goals_business_year: {},
    sales_goals: [],
    sales_goals_list: [],
    fl_goals_business_year: {},
    fl_goals: [],
    fl_goals_list: []
  }
}

const state = initialState()

const getters = {
  getCompany: state => state.company,
  getCompanyAccountItems: state => state.account_items,
  getCompanyUsers: state => state.users,
  getCompanySales: state => state.sales,
  getCompanyCosts: state => state.costs,
  getCompanyBusinessYear: state => state.current_business_year,
  getCompanyBusinessYears: state => state.business_years,
  getCompanyToday: state => state.today,
  getCompanyPerformances: state => state.performances,
  getCompanyBankBalances: state => state.bank_balances,
  getCompanySalesGoals: state => state.sales_goals,
  getCompanySalesGoalsBusinessYear: state => state.sales_goals_business_year,
  getCompanySalesGoalsList: state => state.sales_goals_list,
  getCompanyFLGoal: state => state.fl_goals,
  getCompanyFLGoalBusinessYear: state => state.fl_goals_business_year,
  getCompanyFLGoals: state => state.fl_goals_list
}

const actions = {
  [COMPANY_GET]: ({ commit, dispatch }, params) => {
    commit('COMPANY_REQUEST')
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get(`/admin/companies/${params.id}`)
        .then(response => {
          commit('COMPANY_GET_SUCCESS', { ...response.data.data })

          resolve(response)
        })
        .catch(error => {
          commit('COMPANY_GET_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  },

  [COMPANY_UPDATE]: ({ commit, dispatch }, params) => {
    commit('COMPANY_REQUEST')
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .put(`/admin/companies/${params.id}`, params.data)
        .then(response => {
          commit('COMPANY_UPDATE_SUCCESS', { ...response.data.data })
          dispatch('ALERT', {
            show: true,
            text: response.data.data.message
          })

          resolve(response)
        })
        .catch(error => {
          commit('COMPANY_UPDATE_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  },

  [COMPANY_ACCOUNT_ITEMS_GET]: ({ commit, dispatch }, params) => {
    commit('COMPANY_REQUEST')
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get(`/admin/companies/${params.id}/account-items`, {
          params: {
            ...params.params
          }
        })
        .then(response => {
          commit('COMPANY_ACCOUNT_ITEMS_GET_SUCCESS', { ...response.data.data })

          resolve(response)
        })
        .catch(error => {
          commit('COMPANY_ACCOUNT_ITEMS_GET_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  },

  [COMPANY_ACCOUNT_ITEMS_UPDATE]: ({ commit, dispatch }, params) => {
    commit('COMPANY_REQUEST')
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .put(`/admin/companies/${params.id}/account-items`, {
          account_items: params.data
        })
        .then(response => {
          commit('COMPANY_ACCOUNT_ITEMS_UPDATE_SUCCESS', {
            ...response.data.data
          })
          dispatch('ALERT', {
            show: true,
            text: response.data.data.message
          })

          resolve(response)
        })
        .catch(error => {
          commit('COMPANY_ACCOUNT_ITEMS_UPDATE_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  },

  [COMPANY_USERS_GET]: ({ commit, dispatch }, params) => {
    commit('COMPANY_REQUEST')
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get(`/admin/users`, {
          params: {
            paginate: 0,
            company_id: params.id
          }
        })
        .then(response => {
          commit('COMPANY_USERS_GET_SUCCESS', { ...response.data.data })

          resolve(response)
        })
        .catch(error => {
          commit('COMPANY_USERS_GET_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  },

  [COMPANY_SALES_GET]: ({ commit, dispatch }, params) => {
    commit('COMPANY_REQUEST')
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get(`/admin/companies/${params.id}/deals`, {
          params: {
            ...(params.term === '' ? {} : { term: params.term })
          }
        })
        .then(response => {
          commit('COMPANY_SALES_GET_SUCCESS', { ...response.data.data })

          resolve(response)
        })
        .catch(error => {
          commit('COMPANY_SALES_GET_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  },

  [COMPANY_BUSINESS_YEARS_GET]: ({ commit, dispatch }, params) => {
    commit('COMPANY_REQUEST')
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get(`/admin/companies/${params.id}/business-years`)
        .then(response => {
          commit('COMPANY_BUSINESS_YEARS_GET_SUCCESS', {
            ...response.data.data
          })

          resolve(response)
        })
        .catch(error => {
          commit('COMPANY_BUSINESS_YEARS_GET_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  },

  [COMPANY_PERFORMANCE_GET]: ({ commit, dispatch }, params) => {
    commit('COMPANY_REQUEST')
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/companies/${params.id}/performance`, {
          params: {
            ...(params.term === '' ? {} : { term: params.term })
          }
        })
        .then(response => {
          commit('COMPANY_PERFORMANCE_GET_SUCCESS', { ...response.data.data })

          resolve(response)
        })
        .catch(error => {
          commit('COMPANY_PERFORMANCE_GET_ERROR')

          reject(error)
        })
    })
  },

  [COMPANY_BANK_BALANCE_GET]: ({ commit, dispatch }, params) => {
    commit('COMPANY_REQUEST')
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get(`/admin/companies/${params.id}/bank-balances`, {
          params: {
            ...(params.term === '' ? {} : { term: params.term })
          }
        })
        .then(response => {
          commit('COMPANY_BANK_BALANCE_GET_SUCCESS', { ...response.data.data })

          resolve(response)
        })
        .catch(error => {
          commit('COMPANY_BANK_BALANCE_GET_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  },

  [COMPANY_SALES_GOAL_GET]: ({ commit, dispatch }, params) => {
    commit('COMPANY_REQUEST')
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get(`/admin/companies/${params.id}/sales-goals/show`, {
          params: {
            ...(params.term === '' ? {} : { term: params.term })
          }
        })
        .then(response => {
          commit('COMPANY_SALES_GOAL_GET_SUCCESS', { ...response.data.data })

          resolve(response)
        })
        .catch(error => {
          commit('COMPANY_SALES_GOAL_GET_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  },

  [COMPANY_SALES_GOAL_CREATE]: ({ commit, dispatch }, params) => {
    commit('COMPANY_REQUEST')
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .post(`/admin/companies/${params.id}/sales-goals`, {
          term: params.term,
          goals: params.goals
        })
        .then(response => {
          commit('COMPANY_SALES_GOAL_CREATE_SUCCESS', { ...response.data.data })

          resolve(response)
        })
        .catch(error => {
          commit('COMPANY_SALES_GOAL_CREATE_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  },

  [COMPANY_SALES_GOAL_LIST_GET]: ({ commit, dispatch }, params) => {
    commit('COMPANY_REQUEST')
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get(`/admin/companies/${params.id}/sales-goals`, {
          params: params.params
        })
        .then(response => {
          commit('COMPANY_SALES_GOAL_LIST_GET_SUCCESS', response.data.data)

          resolve(response)
        })
        .catch(error => {
          commit('COMPANY_SALES_GOAL_LIST_GET_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  },

  [COMPANY_SALES_GOAL_DELETE]: ({ commit, dispatch }, params) => {
    commit('COMPANY_REQUEST')
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .delete(
          `/admin/companies/${params.id}/sales-goals/${params.sales_goal_id}`
        )
        .then(response => {
          commit('COMPANY_SALES_GOAL_DELETE_SUCCESS', {
            ...response.data.data
          })

          resolve(response)
        })
        .catch(error => {
          commit('COMPANY_SALES_GOAL_DELETE_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  },

  [COMPANY_FL_GOAL_GET]: ({ commit, dispatch }, params) => {
    commit('COMPANY_REQUEST')
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get(`/admin/companies/${params.id}/performance/summary`, {
          params: {
            ...(params.term === '' ? {} : { term: params.term })
          }
        })
        .then(response => {
          commit('COMPANY_FL_GOAL_GET_SUCCESS', { ...response.data.data })

          resolve(response)
        })
        .catch(error => {
          commit('COMPANY_FL_GOAL_GET_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  },

  [COMPANY_FL_GOAL_CREATE]: ({ commit, dispatch }, params) => {
    commit('COMPANY_REQUEST')
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .post(`/admin/companies/${params.id}/fl-ratios`, {
          term: params.term,
          f_ratio: params.f_ratio,
          l_ratio: params.l_ratio
        })
        .then(response => {
          commit('COMPANY_FL_GOAL_CREATE_SUCCESS', { ...response.data.data })

          resolve(response)
        })
        .catch(error => {
          commit('COMPANY_FL_GOAL_CREATE_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  },

  [COMPANY_FL_GOAL_LIST_GET]: ({ commit, dispatch }, params) => {
    commit('COMPANY_REQUEST')
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get(`/admin/companies/${params.id}/fl-ratios`, {
          params: params.params
        })
        .then(response => {
          commit('COMPANY_FL_GOAL_LIST_GET_SUCCESS', response.data.data)

          resolve(response)
        })
        .catch(error => {
          commit('COMPANY_FL_GOAL_LIST_GET_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  },

  [COMPANY_FL_GOAL_DELETE]: ({ commit, dispatch }, params) => {
    commit('COMPANY_REQUEST')
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .delete(`/admin/companies/${params.id}/fl-ratios/${params.fl_goal_id}`)
        .then(response => {
          commit('COMPANY_FL_GOAL_DELETE_SUCCESS', {
            ...response.data.data
          })

          resolve(response)
        })
        .catch(error => {
          commit('COMPANY_FL_GOAL_DELETE_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  }
}

const mutations = {
  [COMPANY_REQUEST]: state => {
    state.status = 'loading'
  },
  [COMPANY_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.company = params.company
    state.current_business_year = params.company.current_business_year
  },
  [COMPANY_GET_ERROR]: state => {
    state.status = 'error'
  },
  [COMPANY_UPDATE_SUCCESS]: state => {
    state.status = 'success'
  },
  [COMPANY_UPDATE_ERROR]: state => {
    state.status = 'error'
  },
  [COMPANY_ACCOUNT_ITEMS_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.account_items = params.account_items
  },
  [COMPANY_ACCOUNT_ITEMS_GET_ERROR]: state => {
    state.status = 'error'
  },
  [COMPANY_ACCOUNT_ITEMS_UPDATE_SUCCESS]: state => {
    state.status = 'success'
  },
  [COMPANY_ACCOUNT_ITEMS_UPDATE_ERROR]: state => {
    state.status = 'error'
  },
  [COMPANY_USERS_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.users = params.users
  },
  [COMPANY_USERS_GET_ERROR]: state => {
    state.status = 'error'
  },
  [COMPANY_SALES_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.sales = params.sales
    state.costs = params.costs
    state.today = params.today
  },
  [COMPANY_SALES_GET_ERROR]: state => {
    state.status = 'error'
  },
  [COMPANY_BUSINESS_YEARS_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.business_years = params.business_years
  },
  [COMPANY_BUSINESS_YEARS_GET_ERROR]: state => {
    state.status = 'error'
  },
  [COMPANY_PERFORMANCE_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.performances.push(params)
  },
  [COMPANY_PERFORMANCE_GET_ERROR]: state => {
    state.status = 'error'
  },
  [COMPANY_PERFORMANCE_RESET]: state => {
    state.status = 'success'
    state.performances = []
  },
  [COMPANY_BANK_BALANCE_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.bank_balances = params.monthly_balances
  },
  [COMPANY_BANK_BALANCE_GET_ERROR]: state => {
    state.status = 'error'
  },
  [COMPANY_SALES_GOAL_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.sales_goals = params.goals
    state.sales_goals_business_year = params.business_year
  },
  [COMPANY_SALES_GOAL_GET_ERROR]: state => {
    state.status = 'error'
  },
  [COMPANY_SALES_GOAL_CREATE_SUCCESS]: (state, params) => {
    state.status = 'success'
    store.dispatch('ALERT', {
      show: true,
      text: params.message
    })
  },
  [COMPANY_SALES_GOAL_CREATE_ERROR]: (state, params) => {
    state.status = 'error'
    store.dispatch('ALERT', {
      show: true,
      text: params.message
    })
  },
  [COMPANY_SALES_GOAL_LIST_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.sales_goals_list = params
  },
  [COMPANY_SALES_GOAL_LIST_GET_ERROR]: state => {
    state.status = 'error'
  },
  [COMPANY_SALES_GOAL_DELETE_SUCCESS]: (state, params) => {
    state.status = 'success'
    store.dispatch('ALERT', {
      show: true,
      text: params.message
    })
  },
  [COMPANY_SALES_GOAL_DELETE_ERROR]: (state, params) => {
    state.status = 'error'
    store.dispatch('ALERT', {
      show: true,
      text: params.message
    })
  },
  [COMPANY_FL_GOAL_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.fl_goals = {
      sales: params.sales,
      costs: params.costs,
      date: params.date
    }
    state.fl_goals_business_year = params.business_year
  },
  [COMPANY_FL_GOAL_GET_ERROR]: state => {
    state.status = 'error'
  },
  [COMPANY_FL_GOAL_CREATE_SUCCESS]: (state, params) => {
    state.status = 'success'
    store.dispatch('ALERT', {
      show: true,
      text: params.message
    })
  },
  [COMPANY_FL_GOAL_CREATE_ERROR]: (state, params) => {
    state.status = 'error'
    store.dispatch('ALERT', {
      show: true,
      text: params.message
    })
  },
  [COMPANY_FL_GOAL_LIST_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.fl_goals_list = params
  },
  [COMPANY_FL_GOAL_LIST_GET_ERROR]: state => {
    state.status = 'error'
  },
  [COMPANY_FL_GOAL_DELETE_SUCCESS]: (state, params) => {
    state.status = 'success'
    store.dispatch('ALERT', {
      show: true,
      text: params.message
    })
  },
  [COMPANY_FL_GOAL_DELETE_ERROR]: (state, params) => {
    state.status = 'error'
    store.dispatch('ALERT', {
      show: true,
      text: params.message
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
