import axios from 'axios'
import '@/store/actions/users'
import {
  GET_ALL_USERS,
  USER_GET,
  GET_ALL_USERS_ERROR,
  USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_USER_SUCCESS,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  USER_GET_COMPANY_LIST,
  USER_GET_COMPANY_LIST_SUCCESS,
  USER_GET_COMPANY_LIST_ERROR,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  EDIT_USER,
  USER_PASSWORD_RESET_POST,
  USER_PASSWORD_RESET_POST_SUCCESS,
  USER_PASSWORD_RESET_POST_ERROR,
  USER_ADMINS_GET,
  USER_ADMINS_GET_SUCCESS,
  USER_ADMINS_GET_ERROR,
  USER_ADMINS_CREATE,
  USER_ADMINS_CREATE_SUCCESS,
  USER_ADMINS_CREATE_ERROR,
  USER_ADMINS_EDIT,
  USER_ADMINS_EDIT_SUCCESS,
  USER_ADMINS_EDIT_ERROR,
  USER_ADMINS_PASSWORD_RESET,
  USER_ADMINS_PASSWORD_RESET_SUCCESS,
  USER_ADMINS_PASSWORD_RESET_ERROR,
  USER_ADMINS_DELETE,
  USER_ADMINS_DELETE_SUCCESS,
  USER_ADMINS_DELETE_ERROR
} from '@/store/actions/users'

function initialState() {
  return {
    status: '',
    pagination: {},
    users: [],
    user: {},
    message: '',
    errors: {},
    companies: [],
    admin_users: [],
    admin_users_pagination: {}
  }
}

const state = initialState()

const getters = {
  allUsers: state => state.users,
  singleUser: state => state.user,
  singleUserCompany: state => state.user.company,
  userPagination: state => state.pagination,
  userMessage: state => state.message,
  userGetCompanyList: state => state.companies,
  getAdminUsers: state => state.admin_users,
  getAdminUsersPagination: state => state.admin_users_pagination
}

const actions = {
  [GET_ALL_USERS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get('/admin/users', {
          params: params
        })
        .then(response => {
          let data = response.data

          commit('GET_ALL_USERS_SUCCESS', {
            users: data.data.users,
            pagination: data.paginate
          })

          resolve(response)
        })
        .catch(error => {
          commit('GET_ALL_USERS_ERROR', { error: error })

          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [USER_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get(`/admin/users/${params.id}`)
        .then(response => {
          let data = response.data

          commit('GET_USER_SUCCESS', { user: data.data.user })

          resolve(response)
        })
        .catch(error => {
          commit('GET_USER_ERROR', { error: error })

          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [CREATE_USER]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .post(`/admin/users`, params, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          commit('CREATE_USER_SUCCESS', { message: response.data.data.message })
          dispatch('ALERT', {
            show: true,
            text: response.data.data.message
          })

          resolve(response)
        })
        .catch(error => {
          dispatch('ALERT', {
            show: true,
            text: error.data.error.message
          })
          if (error.status === 422) {
            commit('CREATE_USER_ERROR', {
              message: error.data.error.message,
              errors: error.data.error.errors
            })
            reject(error)
          }

          commit('CREATE_USER_ERROR', { message: error.response.data.message })
          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [DELETE_USER]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .delete(`/admin/users/${params.id}`)
        .then(response => {
          let data = response.data

          commit('DELETE_USER_SUCCESS', { user: data.data.user })

          resolve(response)
        })
        .catch(error => {
          commit('DELETE_USER_ERROR', { error: error })

          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [USER_PASSWORD_RESET_POST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .post(`/user/auth/forgot-password`, params)
        .then(response => {
          commit('USER_PASSWORD_RESET_POST_SUCCESS', {
            message: response.data.data.message
          })
          dispatch('ALERT', {
            show: true,
            text: response.data.data.message
          })

          resolve(response)
        })
        .catch(error => {
          dispatch('ALERT', {
            show: true,
            text: error.data.error.message
          })
          if (error.status === 422) {
            commit('USER_PASSWORD_RESET_POST_ERROR', {
              message: error.data.error.message,
              errors: error.data.error.errors
            })
            reject(error)
          }

          commit('USER_PASSWORD_RESET_POST_ERROR', {
            message: error.response.data.message
          })
          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [EDIT_USER]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .post(`/admin/users/${params.id}/update`, params.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          commit('EDIT_USER_SUCCESS', { message: response.data.data.message })
          dispatch('ALERT', {
            show: true,
            text: response.data.data.message
          })

          resolve(response)
        })
        .catch(error => {
          dispatch('ALERT', {
            show: true,
            text: error.data.error.message
          })
          if (error.status === 422) {
            commit('EDIT_USER_ERROR', {
              message: error.data.error.message,
              errors: error.data.error.errors
            })
            reject(error)
          }

          commit('EDIT_USER_ERROR', { message: error.response.data.message })
          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [USER_GET_COMPANY_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get('/admin/companies/all', {
          params: params
        })
        .then(response => {
          let data = response.data

          commit('USER_GET_COMPANY_LIST_SUCCESS', {
            companies: data.data.companies
          })

          resolve(response)
        })
        .catch(error => {
          commit('USER_GET_COMPANY_LIST_ERROR', { error: error })

          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [USER_ADMINS_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get('/admin/admins', {
          params: params
        })
        .then(response => {
          let data = response.data

          commit('USER_ADMINS_GET_SUCCESS', {
            admins: data.data.admins,
            pagination: data.paginate
          })

          resolve(response)
        })
        .catch(error => {
          commit('USER_ADMINS_GET_ERROR', { error: error })

          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [USER_ADMINS_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .post(`/admin/admins`, params, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          commit('USER_ADMINS_CREATE_SUCCESS', {
            message: response.data.data.message
          })
          dispatch('ALERT', {
            show: true,
            text: response.data.data.message
          })

          resolve(response)
        })
        .catch(error => {
          dispatch('ALERT', {
            show: true,
            text: error.data.error.message
          })
          if (error.status === 422) {
            commit('USER_ADMINS_CREATE_ERROR', {
              message: error.data.error.message,
              errors: error.data.error.errors
            })
            reject(error)
          }

          commit('USER_ADMINS_CREATE_ERROR', {
            message: error.response.data.message
          })
          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [USER_ADMINS_EDIT]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .post(`/admin/admins/${params.id}/update`, params.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          commit('USER_ADMINS_EDIT_SUCCESS', {
            message: response.data.data.message
          })
          dispatch('ALERT', {
            show: true,
            text: response.data.data.message
          })

          resolve(response)
        })
        .catch(error => {
          dispatch('ALERT', {
            show: true,
            text: error.data.error.message
          })
          if (error.status === 422) {
            commit('USER_ADMINS_EDIT_ERROR', {
              message: error.data.error.message,
              errors: error.data.error.errors
            })
            reject(error)
          }

          commit('USER_ADMINS_EDIT_ERROR', {
            message: error.response.data.message
          })
          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [USER_ADMINS_PASSWORD_RESET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .post(`/admin/auth/forgot-password`, params)
        .then(response => {
          commit('USER_ADMINS_PASSWORD_RESET_SUCCESS', {
            message: response.data.data.message
          })
          dispatch('ALERT', {
            show: true,
            text: response.data.data.message
          })

          resolve(response)
        })
        .catch(error => {
          dispatch('ALERT', {
            show: true,
            text: error.data.error.message
          })
          if (error.status === 422) {
            commit('USER_ADMINS_PASSWORD_RESET_ERROR', {
              message: error.data.error.message,
              errors: error.data.error.errors
            })
            reject(error)
          }

          commit('USER_PASSWORD_RESET_POST_ERROR', {
            message: error.response.data.message
          })
          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [USER_ADMINS_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .delete(`/admin/admins/${params.id}`)
        .then(response => {
          let data = response.data

          commit('USER_ADMINS_DELETE_SUCCESS', data)

          resolve(response)
        })
        .catch(error => {
          commit('USER_ADMINS_DELETE_ERROR', { error: error })

          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  }
}

const mutations = {
  [USERS_REQUEST]: state => {
    state.status = 'loading'
  },
  [GET_ALL_USERS_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.users = params.users
    state.pagination = params.pagination
  },
  [GET_ALL_USERS_ERROR]: state => {
    state.status = 'error'
  },
  [GET_USER_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.user = params.user
  },
  [CREATE_USER_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.message = params.message
  },
  [CREATE_USER_ERROR]: (state, params) => {
    state.status = 'error'
    state.message = params.message
    state.errors = params.errors
  },
  [EDIT_USER_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.message = params.message
  },
  [EDIT_USER_ERROR]: (state, params) => {
    state.status = 'error'
    state.message = params.message
    state.errors = params.errors
  },
  [DELETE_USER_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.user = params.user
  },
  [DELETE_USER_ERROR]: (state, params) => {
    state.status = 'success'
    state.message = params.message
    state.errors = params.errors
  },
  [USER_GET_COMPANY_LIST_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.companies = params.companies
  },
  [USER_GET_COMPANY_LIST_ERROR]: state => {
    state.status = 'error'
  },
  [USER_PASSWORD_RESET_POST_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.message = params.message
  },
  [USER_PASSWORD_RESET_POST_ERROR]: (state, params) => {
    state.status = 'error'
    state.message = params.message
    state.errors = params.errors
  },
  [USER_ADMINS_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.admin_users = params.admins
    state.admin_users_pagination = params.pagination
  },
  [USER_ADMINS_GET_ERROR]: state => {
    state.status = 'error'
  },
  [USER_ADMINS_CREATE_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.message = params.message
  },
  [USER_ADMINS_CREATE_ERROR]: (state, params) => {
    state.status = 'error'
    state.message = params.message
    state.errors = params.errors
  },
  [USER_ADMINS_EDIT_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.message = params.message
  },
  [USER_ADMINS_EDIT_ERROR]: (state, params) => {
    state.status = 'error'
    state.message = params.message
    state.errors = params.errors
  },
  [USER_ADMINS_PASSWORD_RESET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.message = params.message
  },
  [USER_ADMINS_PASSWORD_RESET_ERROR]: (state, params) => {
    state.status = 'error'
    state.message = params.message
    state.errors = params.errors
  },
  [USER_ADMINS_DELETE_SUCCESS]: state => {
    state.status = 'success'
  },
  [USER_ADMINS_DELETE_ERROR]: state => {
    state.status = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
