export default {
  light: {
    primary: '#7367F0',
    secondary: '#525252',
    error: '#790000',
    warning: '#FFAD32',
    success: '#0B8C00',
    info: '#0094FF',
    textColor: '#B9B9B9',
    iconColor: '#FFFFFF59',
    grayBold: '#4a4a4a',
  }
}
