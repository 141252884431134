<template>
  <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.842773" y="4.20703" width="3" height="7" transform="rotate(-45 0.842773 4.20703)" fill="currentColor"/>
    <path d="M5.79297 9.15674L3.67165 7.03542C6.15693 4.55014 7.55033 3.15674 10.0356 0.671457L12.1569 2.79278L5.79297 9.15674Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
name: "CheckIcon"
}
</script>

<style scoped>

</style>