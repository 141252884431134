import axios from 'axios'
import {
  GROUP_GET_ALL,
  GROUP_DELETE,
  GROUP_GET_ALL_ERROR,
  GROUP_GET_ALL_SUCCESS,
  GROUP_ADD_NEW,
  GROUP_ADD_NEW_SUCCESS,
  GROUP_ADD_NEW_ERROR,
  GROUP_DELETE_SUCCESS,
  GROUP_DELETE_ERROR,
  GROUP_EDIT,
  GROUP_EDIT_SUCCESS,
  GROUP_EDIT_ERROR,
  GROUP_GET,
  GROUP_GET_SUCCESS,
  GROUP_GET_ERROR
} from '@/store/actions/usergroup'

function initialState() {
  return {
    status: '',
    paginationGroup: {},
    groups: [],
    group: {},
    message: '',
    errors: {}
  }
}

const state = initialState()

const getters = {
  groups: state => state.groups,
  group: state => state.group,
  paginationGroup: state => state.paginationGroup
}

const actions = {
  [GROUP_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get('/admin/user-group', {
          params: params
        })
        .then(response => {
          let data = response.data

          commit('GROUP_GET_ALL_SUCCESS', {
            groups: data.data.groups,
            pagination: data.paginate
          })

          resolve(response)
        })
        .catch(error => {
          commit('GROUP_GET_ALL_ERROR', { error: error })

          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [GROUP_ADD_NEW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .post(`/admin/user-group`, params)
        .then(response => {
          commit('GROUP_ADD_NEW_SUCCESS', {
            message: response.data.data.message
          })
          dispatch('ALERT', {
            show: true,
            text: response.data.data.message
          })

          resolve(response)
        })
        .catch(error => {
          dispatch('ALERT', {
            show: true,
            text: error.data.error.message
          })
          if (error.status === 422) {
            commit('GROUP_ADD_NEW_ERROR', {
              message: error.data.error.message,
              errors: error.data.error.errors
            })
            reject(error)
          }

          commit('GROUP_ADD_NEW_ERROR', {
            message: error.response.data.message
          })
          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [GROUP_EDIT]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .put(`/admin/user-group/${params.id}`, params)
        .then(response => {
          commit('GROUP_EDIT_SUCCESS', {
            message: response.data.data.message
          })
          dispatch('ALERT', {
            show: true,
            text: response.data.data.message
          })

          resolve(response)
        })
        .catch(error => {
          dispatch('ALERT', {
            show: true,
            text: error.data.error.message
          })
          if (error.status === 422) {
            commit('GROUP_EDIT_ERROR', {
              message: error.data.error.message,
              errors: error.data.error.errors
            })
            reject(error)
          }

          commit('GROUP_EDIT_ERROR', {
            message: error.response.data.message
          })
          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [GROUP_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get(`/admin/group/groups/${params.id}`)
        .then(response => {
          let data = response.data

          commit('GROUP_GET_SUCCESS', {
            group: data.data.groups,
            pagination: data.paginate
          })

          resolve(response)
        })
        .catch(error => {
          commit('GROUP_GET_ERROR', { error: error })

          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [GROUP_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .delete(`/admin/user-group/${params.id}`, params)
        .then(response => {
          commit('GROUP_DELETE_SUCCESS', {
            message: response.data.data.message
          })
          dispatch('ALERT', {
            show: true,
            text: response.data.data.message
          })

          resolve(response)
        })
        .catch(error => {
          dispatch('ALERT', {
            show: true,
            text: error.data.error.message
          })
          if (error.status === 422) {
            commit('GROUP_DELETE_ERROR', {
              message: error.data.error.message,
              errors: error.data.error.errors
            })
            reject(error)
          }

          commit('GROUP_DELETE_ERROR', {
            message: error.response.data.message
          })
          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  }
}

const mutations = {
  [GROUP_GET_ALL_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.groups = params.groups
    state.paginationGroup = params.pagination
  },
  [GROUP_GET_ALL_ERROR]: state => {
    state.status = 'error'
  },
  [GROUP_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.groups = params.groups
  },
  [GROUP_GET_ERROR]: state => {
    state.status = 'error'
  },
  [GROUP_ADD_NEW_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.message = params.message
  },
  [GROUP_ADD_NEW_ERROR]: (state, params) => {
    state.status = 'error'
    state.message = params.message
    state.errors = params.errors
  },
  [GROUP_EDIT_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.message = params.message
  },
  [GROUP_EDIT_ERROR]: (state, params) => {
    state.status = 'error'
    state.message = params.message
    state.errors = params.errors
  },

  [GROUP_DELETE_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.message = params.message
  },
  [GROUP_DELETE_ERROR]: (state, params) => {
    state.status = 'error'
    state.message = params.message
    state.errors = params.errors
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
