import {
  required,
  email,
  numeric,
  max_value,
  size,
  confirmed
} from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'

extend('max', {
  ...max_value,
  message: 'The sum of F and L ratio must be less or equal to 100.'
})

extend('required', {
  ...required,
  message: '{_field_} cannot be empty.'
})

extend('email', {
  ...email,
  message: 'Email must be valid.'
})

extend('numeric', {
  ...numeric,
  message: '{_field_} must be number.'
})

extend('tel', {
  computesRequired: true,
  validate: value => /^(\d+-?)+\d+$/.test(value),
  message: '{_field_} can only have numbers and dash (must not end with dash).'
})

extend('password', {
  computesRequired: true,
  validate: value =>
    /(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/.test(
      value
    ),
  message: 'Invalid password. Please follow the format below.'
})

extend('size', {
  ...size,
  message: 'Maximum size is 5MB'
})

extend('confirmed', {
  ...confirmed,
  message: 'Please make sure the confirmation match.'
})
