export const GET_ALL_TAGS = 'GET_ALL_TAGS'
export const TAG_GET = 'TAG_GET'
export const ADD_TAG = 'ADD_TAG'
export const EDIT_TAG = 'EDIT_TAG'
export const TAGS_REQUEST = 'TAGS_REQUEST'

export const GET_ALL_TAGS_SUCCESS = 'GET_ALL_TAGS_SUCCESS'
export const GET_ALL_TAGS_ERROR = 'GET_ALL_TAGS_ERROR'
export const GET_TAG_SUCCESS = 'GET_TAG_SUCCESS'
export const GET_TAG_ERROR = 'GET_TAG_ERROR'
export const ADD_TAG_SUCCESS = 'ADD_TAG_SUCCESS'
export const ADD_TAG_ERROR = 'ADD_TAG_ERROR'
export const EDIT_TAG_SUCCESS = 'EDIT_TAG_SUCCESS'
export const EDIT_TAG_ERROR = 'EDIT_TAG_ERROR'

export const DELETE_TAG = 'DELETE_TAG'
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS'
export const DELETE_TAG_ERROR = 'DELETE_TAG_ERROR'
