import axios from 'axios'
import {
  FAQ_GET_ALL,
  FAQ_DELETE,
  FAQ_GET_ALL_ERROR,
  FAQ_GET_ALL_SUCCESS,
  FAQ_ADD_NEW,
  FAQ_ADD_NEW_SUCCESS,
  FAQ_ADD_NEW_ERROR,
  FAQ_DELETE_SUCCESS,
  FAQ_DELETE_ERROR,
  FAQ_GET_CATEGORY_LIST_SUCCESS,
  FAQ_GET_CATEGORY_LIST_ERROR,
  FAQ_GET_CATEGORY_LIST,
  FAQ_EDIT,
  FAQ_EDIT_SUCCESS,
  FAQ_EDIT_ERROR,
  FAQ_GET,
  FAQ_GET_SUCCESS,
  FAQ_GET_ERROR,  
} from '@/store/actions/faq'

function initialState() {
  return {
    status: '',
    pagination: {},
    faqs: [],
    faqCategories: [],
    faq: {},
    message: '',
    errors: {}
  }
}

const state = initialState()

const getters = {
  allFaqs: state => state.faqs,
  faq: state => state.faq,
  faqCategories: state => state.faqCategories,
  singleFaq: state => state.faq,
  pagination: state => state.pagination
}

const actions = {
  [FAQ_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get('/admin/faq/faqs', {
          params: params
        })
        .then(response => {
          let data = response.data

          commit('FAQ_GET_ALL_SUCCESS', {
            faq: data.data.faqs,
            pagination: data.paginate
          })

          resolve(response)
        })
        .catch(error => {
          commit('FAQ_GET_ALL_ERROR', { error: error })

          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [FAQ_ADD_NEW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .post(`/admin/faq/faqs`, params)
        .then(response => {
          commit('FAQ_ADD_NEW_SUCCESS', {
            message: response.data.data.message
          })
          dispatch('ALERT', {
            show: true,
            text: response.data.data.message
          })

          resolve(response)
        })
        .catch(error => {
          dispatch('ALERT', {
            show: true,
            text: error.data.error.message
          })
          if (error.status === 422) {
            commit('FAQ_ADD_NEW_ERROR', {
              message: error.data.error.message,
              errors: error.data.error.errors
            })
            reject(error)
          }

          commit('FAQ_ADD_NEW_ERROR', {
            message: error.response.data.message
          })
          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [FAQ_EDIT]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .put(`/admin/faq/faqs/${params.id}`, params)
        .then(response => {
          commit('FAQ_EDIT_SUCCESS', {
            message: response.data.data.message
          })
          dispatch('ALERT', {
            show: true,
            text: response.data.data.message
          })

          resolve(response)
        })
        .catch(error => {
          dispatch('ALERT', {
            show: true,
            text: error.data.error.message
          })
          if (error.status === 422) {
            commit('FAQ_EDIT_ERROR', {
              message: error.data.error.message,
              errors: error.data.error.errors
            })
            reject(error)
          }

          commit('FAQ_EDIT_ERROR', {
            message: error.response.data.message
          })
          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [FAQ_GET_CATEGORY_LIST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get('/admin/faq/categories')
        .then(response => {
          let data = response.data

          commit('FAQ_GET_CATEGORY_LIST_SUCCESS', {
            categories: data.data.categories,
            pagination: data.paginate
          })

          resolve(response)
        })
        .catch(error => {
          commit('FAQ_GET_CATEGORY_LIST_ERROR', { error: error })

          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [FAQ_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get(`/admin/faq/faqs/${params.id}`)
        .then(response => {
          let data = response.data

          commit('FAQ_GET_SUCCESS', {
            faq: data.data.faq,
            pagination: data.paginate
          })

          resolve(response)
        })
        .catch(error => {
          commit('FAQ_GET_ERROR', { error: error })

          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [FAQ_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .delete(`/admin/faq/faqs/${params.id}`, params)
        .then(response => {
          commit('FAQ_DELETE_SUCCESS', {
            message: response.data.data.message
          })
          dispatch('ALERT', {
            show: true,
            text: response.data.data.message
          })

          resolve(response)
        })
        .catch(error => {
          dispatch('ALERT', {
            show: true,
            text: error.data.error.message
          })
          if (error.status === 422) {
            commit('FAQ_DELETE_ERROR', {
              message: error.data.error.message,
              errors: error.data.error.errors
            })
            reject(error)
          }

          commit('FAQ_DELETE_ERROR', {
            message: error.response.data.message
          })
          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  }  
}

const mutations = {
  [FAQ_GET_ALL_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.faqs = params.faq
    state.pagination = params.pagination
  },
  [FAQ_GET_ALL_ERROR]: state => {
    state.status = 'error'
  },
  [FAQ_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.faq = params.faq
  },
  [FAQ_GET_ERROR]: state => {
    state.status = 'error'
  },
  [FAQ_GET_CATEGORY_LIST_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.faqCategories = params.categories
    state.pagination = params.pagination
  },
  [FAQ_GET_CATEGORY_LIST_ERROR]: state => {
    state.status = 'error'
  },

  [FAQ_ADD_NEW_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.message = params.message
  },
  [FAQ_ADD_NEW_ERROR]: (state, params) => {
    state.status = 'error'
    state.message = params.message
    state.errors = params.errors
  },
  [FAQ_EDIT_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.message = params.message
  },
  [FAQ_EDIT_ERROR]: (state, params) => {
    state.status = 'error'
    state.message = params.message
    state.errors = params.errors
  },

  [FAQ_DELETE_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.message = params.message
  },
  [FAQ_DELETE_ERROR]: (state, params) => {
    state.status = 'error'
    state.message = params.message
    state.errors = params.errors
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
