export const FAQ_GET_ALL = 'FAQ_GET_ALL'
export const FAQ_GET_ALL_SUCCESS = 'FAQ_GET_ALL_SUCCESS'
export const FAQ_GET_ALL_ERROR = 'FAQ_GET_ALL_ERROR'
export const FAQ_GET_CATEGORY_LIST = 'FAQ_GET_CATEGORY_LIST'
export const FAQ_GET_CATEGORY_LIST_SUCCESS = 'FAQ_GET_CATEGORY_LIST_SUCCESS'
export const FAQ_GET_CATEGORY_LIST_ERROR = 'FAQ_GET_CATEGORY_LIST_ERROR'
export const FAQ_ADD_NEW = 'FAQ_ADD_NEW'
export const FAQ_ADD_NEW_SUCCESS = 'FAQ_ADD_NEW_SUCCESS'
export const FAQ_ADD_NEW_ERROR = 'FAQ_ADD_NEW_ERROR'
export const FAQ_DELETE = 'FAQ_DELETE'
export const FAQ_DELETE_SUCCESS = 'FAQ_DELETE_SUCCESS'
export const FAQ_DELETE_ERROR = 'FAQ_DELETE_ERROR'
export const FAQ_EDIT = 'FAQ_EDIT'
export const FAQ_EDIT_SUCCESS = 'FAQ_EDIT_SUCCESS'
export const FAQ_EDIT_ERROR = 'FAQ_EDIT_ERROR'

export const FAQ_GET = 'FAQ_GET'
export const FAQ_GET_SUCCESS = 'FAQ_GET_SUCCESS'
export const FAQ_GET_ERROR = 'FAQ_GET_ERROR'
