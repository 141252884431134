<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#F7F7F7" stroke="#C4C4C4"/>
    <rect x="3" y="8.33301" width="3.50187" height="7.8792" rx="1" transform="rotate(-45 3 8.33301)" fill="currentColor"/>
    <rect x="8.57129" y="13.9048" width="3.50186" height="10.5056" rx="1" transform="rotate(-135 8.57129 13.9048)" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
name: "CheckboxOnIcon"
}
</script>

<style scoped>

</style>