<template>
  <svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L3 3L1 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
name: "CustomChevronRight"
}
</script>

<style scoped>

</style>