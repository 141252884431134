<template>
  <v-app-bar class="main-header" height="64" fixed color="white">
    <v-toolbar-title>
      <router-link to="/">
        <v-img src="@/assets/images/cp-logo-blue.png"></v-img>
      </router-link>
    </v-toolbar-title>
    <v-btn icon class="mx-1" @click.stop="TOGGLE_DRAWER">
      <v-icon size="28" color="#20368B">mdi-menu</v-icon>
    </v-btn>
    <v-spacer></v-spacer>

    <!--    <Search />-->

    <v-menu min-width="180" offset-y bottom left nudge-bottom="10">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mr-0" color="white" depressed v-bind="attrs" v-on="on">
          {{ user ? `${user.last_name} ${user.first_name}`  : 'Admin' }} さん
        </v-btn>
      </template>
      <v-list>
        <div class="text-h5 grey--text text--darken-3 px-4 pt-4">
          {{ user ? `${user.last_name} ${user.first_name}` : '' }}
        </div>
        <div class="subtitle-2 primary--text font-weight-regular px-4">
          {{ user ? user.email : '' }}
        </div>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, i) in account" :key="i">
            <v-list-item-icon class="mr-4">
              <v-icon :color="item.color" v-text="item.icon"> </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                :color="config.light.textColor"
                v-text="item.text"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <div class="d-flex justify-center my-3">
          <v-btn
            width="80%"
            large
            outlined
            color="primary"
            class="text-capitalize"
            @click="logOut"
            >{{ $t('sign_out') }}
          </v-btn>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import config from '@/config'
// import Search from '@/components/admin/partials/Search/Search'

export default {
  name: 'Header',
  // components: { Search },
  data: () => ({
    config,
    searchCollapse: true,
    notifications: [
      {
        text: 'Check out this awesome ticket',
        icon: 'mdi-tag',
        color: 'warning'
      },
      {
        text: 'What is the best way to get ...',
        icon: 'mdi-thumb-up',
        color: 'success'
      },
      {
        text: 'This is just a simple notification',
        icon: 'mdi-flag',
        color: 'error'
      },
      {
        text: '12 new orders has arrived today',
        icon: 'mdi-cart',
        color: 'primary'
      }
    ],
    messages: [
      {
        text: 'JH',
        name: 'Jane Hew',
        message: 'Hey! How is it going?',
        time: '09:32',
        color: 'warning'
      },
      {
        text: 'LB',
        name: 'Lloyd Brown',
        message: 'Check out my new Dashboard',
        time: '10:02',
        color: 'success'
      },
      {
        text: 'MW',
        name: 'Mark Winstein',
        message: 'I want rearrange the appointment',
        time: '12:16',
        color: 'error'
      },
      {
        text: 'LD',
        name: 'Liana Dutti',
        message: 'Good news from sale department',
        time: '14:56',
        color: 'primary'
      }
    ],
    account: [
      // { text: 'Profile', icon: 'mdi-account', color: 'textColor' }
    ],
    notificationsBadge: true,
    messageBadge: true,
    user: null
  }),
  computed: {
    ...mapState(['drawer']),
    DRAWER_STATE: {
      get() {
        return this.drawer
      }
    }
  },
  watch: {
    '$store.state.auth.user': function() {
      this.user = this.$store.state.auth.user
    }
  },
  methods: {
    ...mapActions(['TOGGLE_DRAWER']),
    logOut: function() {
      this.$store.dispatch('AUTH_LOGOUT')
    }
  },
  mounted() {
    this.user = this.$store.state.auth.user
  }
}
</script>

<style src="./Header.scss" lang="scss"></style>
