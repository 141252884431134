export const MASTER_REQUEST = 'MASTER_REQUEST'
export const MASTER_ALL_GET = 'MASTER_ALL_GET'
export const MASTER_ALL_GET_SUCCESS = 'MASTER_ALL_GET_SUCCESS'
export const MASTER_ALL_GET_ERROR = 'MASTER_ALL_GET_ERROR'
export const MASTER_PREFECTURES_GET = 'MASTER_PREFECTURES_GET'
export const MASTER_PREFECTURES_GET_SUCCESS = 'MASTER_PREFECTURES_GET_SUCCESS'
export const MASTER_PREFECTURES_GET_ERROR = 'MASTER_PREFECTURES_GET_ERROR'
export const MASTER_SUBSCRIPTION_PLANS_GET = 'MASTER_SUBSCRIPTION_PLANS_GET'
export const MASTER_SUBSCRIPTION_PLANS_GET_SUCCESS =
  'MASTER_SUBSCRIPTION_PLANS_GET_SUCCESS'
export const MASTER_SUBSCRIPTION_PLANS_GET_ERROR =
  'MASTER_SUBSCRIPTION_PLANS_GET_ERROR'
export const MASTER_COMPANY_TYPES_GET = 'MASTER_COMPANY_TYPES_GET'
export const MASTER_COMPANY_TYPES_GET_SUCCESS =
  'MASTER_COMPANY_TYPES_GET_SUCCESS'
export const MASTER_COMPANY_TYPES_GET_ERROR = 'MASTER_COMPANY_TYPES_GET_ERROR'
export const MASTER_HEAD_COUNTS_GET = 'MASTER_HEAD_COUNTS_GET'
export const MASTER_HEAD_COUNTS_GET_SUCCESS = 'MASTER_HEAD_COUNTS_GET_SUCCESS'
export const MASTER_HEAD_COUNTS_GET_ERROR = 'MASTER_HEAD_COUNTS_GET_ERROR'
