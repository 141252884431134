import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'

import Layout from '@/components/admin/layout/Layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/login',
    component: () => import('@/views/Home.vue'),
    meta: {
      public: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      public: true,
      guest: true
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      public: true,
      guest: true
    }
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      public: true,
      guest: true
    }
  },
  {
    path: '/admin',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/admin/companies',
        name: 'Companies',
        component: () => import('@/views/admin/Company/List/index.vue')
      },
      {
        path: '/admin/companies/:id',
        name: 'CompanyDetail',
        component: () => import('@/views/admin/Company/Detail/index'),
        children: [
          {
            path: 'info',
            name: 'CompanyDetailInfo',
            component: () => import('@/views/admin/Company/Detail/Info/index')
          },
          {
            path: 'accounting',
            name: 'CompanyDetailAccounting',
            component: () =>
              import('@/views/admin/Company/Detail/Accounting/index')
          },
          {
            path: 'performance',
            name: 'CompanyDetailPerformance',
            component: () =>
              import('@/views/admin/Company/Detail/Performance/index')
          },
          {
            path: 'sales-cost',
            name: 'CompanyDetailSalesCost',
            component: () =>
              import('@/views/admin/Company/Detail/SalesCost/index')
          },
          {
            path: 'bank-balance',
            name: 'CompanyDetailBankBalance',
            component: () =>
              import('@/views/admin/Company/Detail/BankBalance/index')
          },
          {
            path: 'fl-goal',
            name: 'CompanyDetailFLGoal',
            component: () => import('@/views/admin/Company/Detail/FLGoal/index')
          },
          {
            path: 'fl-goal/create',
            name: 'CompanyDetailFLGoalCreate',
            component: () =>
              import('@/views/admin/Company/Setting/CreateFLGoal/index')
          },
          {
            path: 'sales-goal',
            name: 'CompanyDetailSalesGoal',
            component: () =>
              import('@/views/admin/Company/Detail/SalesGoal/index')
          },
          {
            path: 'sales-goal/create',
            name: 'CompanyDetailSalesCostGoalCreate',
            component: () =>
              import('@/views/admin/Company/Setting/CreateSalesGoal/index')
          }
        ]
      },
      {
        path: '/users',
        name: 'UserList',
        component: () => import('@/views/admin/User/List/index')
      },
      {
        path: '/users/create',
        name: 'UserCreate',
        component: () => import('@/views/admin/User/Create/index')
      },
      {
        path: '/users/:id/edit',
        name: 'UserEdit',
        component: () => import('@/views/admin/User/Edit/index')
      },
      {
        path: '/users/:id/detail',
        name: 'UserDetail',
        component: () => import('@/views/admin/User/Detail/index')
      },
      {
        path: '/users/groups',
        name: 'UserGroups',
        component: () => import('@/views/admin/User/Group/index')
      },
      {
        path: '/notification/list',
        name: 'NotificationList',
        component: () => import('@/views/admin/Notification/List/index')
      },
      {
        path: '/notification/draft',
        name: 'NotificationDraft',
        component: () => import('@/views/admin/Notification/Draft/index')
      },
      {
        path: '/notification/linkpost',
        name: 'NotificationLinkPost',
        component: () => import('@/views/admin/Notification/LinkPost/index')
      },
      {
        path: '/notification/category-tag',
        name: 'NotificationLinkColumn',
        component: () => import('@/views/admin/Notification/CategoryTags/index')
      },
      {
        path: '/notification/create',
        name: 'NotificationCreate',
        component: () => import('@/views/admin/Notification/Editor/index')
      },
      {
        path: '/notification/:id/edit',
        name: 'NotificationEdit',
        component: () => import('@/views/admin/Notification/Editor/edit')
      },
      {
        path: '/admin-users',
        name: 'AdminUsers',
        component: () => import('@/views/admin/Setting/AdminUser/List/index')
      },
      {
        path: '/admin-users/create',
        name: 'AdminUsersCreate',
        component: () => import('@/views/admin/Setting/AdminUser/Create/index')
      },
      {
        path: '/admin-users/:id/edit',
        name: 'AdminUsersEdit',
        component: () => import('@/views/admin/Setting/AdminUser/Edit/index')
      },
      {
        path: '/faq',
        name: 'Faq',
        component: () => import('@/views/admin/Setting/Faq/List/index')
      },
      {
        path: '/faq/create',
        name: 'FaqCreate',
        component: () => import('@/views/admin/Setting/Faq/Create/index')
      },
      {
        path: '/faq/:id/edit',
        name: 'FaqEdit',
        component: () => import('@/views/admin/Setting/Faq/Edit/index')
      }
    ]
  },
  {
    path: '*',
    name: 'Error',
    component: () => import('@/views/error/Error.vue'),
    meta: {
      public: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

// Auth guard
router.beforeEach((to, from, next) => {
  const authRequired = !to.matched.some(record => record.meta.public)
  const loggedIn = store.getters.token
  //const isFreeeCodeAccess = store.getters.isFreeeCodeAccess
  if (authRequired && !loggedIn) {
    next({
      path: '/login',
      query: {
        redirect: to.fullPath
      }
    })
  } else if (!authRequired && loggedIn) {
    next({
      name: 'Companies',
      query: { status: 'Connect', type: 'corporate' }
    })
  }
  next()
})

export default router
