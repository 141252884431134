<template>
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.6606 14.6366C5.21935 14.6366 3.7781 14.6416 2.33685 14.6351C0.77685 14.6283 0.00934967 13.8586 0.00734967 12.3038C0.00409967 10.0866 -0.0164004 7.86855 0.0295996 5.6518C0.0370996 5.29205 0.25885 4.8258 0.53685 4.60055C2.3616 3.1218 4.2351 1.7028 6.07835 0.246302C6.50035 -0.0866975 6.84085 -0.0789475 7.2586 0.250802C9.08485 1.69255 10.9201 3.12355 12.7678 4.53855C13.1793 4.8538 13.3391 5.21105 13.3346 5.72605C13.3138 7.9438 13.3298 10.1611 13.3241 12.3783C13.3201 13.8363 12.5286 14.6238 11.0516 14.6341C9.58785 14.6443 8.12435 14.6366 6.6606 14.6366ZM9.3296 13.2823C9.52585 13.2938 9.61435 13.3031 9.7026 13.3033C10.1463 13.3051 10.5908 13.3106 11.0346 13.3031C11.7706 13.2903 11.9893 13.0788 11.9911 12.3508C11.9966 10.2643 12.0043 8.1778 11.9778 6.09105C11.9746 5.8513 11.8226 5.53755 11.6361 5.38755C10.1304 4.1803 8.5946 3.0103 7.0811 1.81255C6.7951 1.5863 6.59235 1.5368 6.28035 1.7858C4.75235 3.0008 3.20135 4.18705 1.6706 5.3988C1.51835 5.5193 1.35485 5.74155 1.3531 5.9178C1.33235 8.13755 1.32035 10.3576 1.36935 12.5766C1.37485 12.8201 1.7476 13.2241 1.99435 13.2613C2.63635 13.3593 3.30285 13.2943 4.00185 13.2943C4.00185 11.3353 3.9976 9.45205 4.0041 7.56855C4.00635 6.8733 4.2261 6.65255 4.91335 6.64705C5.97885 6.6388 7.0441 6.64455 8.10985 6.6448C9.2116 6.6453 9.32885 6.7623 9.32935 7.86005C9.3301 9.6523 9.3296 11.4443 9.3296 13.2823ZM5.3711 8.00905C5.3711 9.77705 5.3711 11.5131 5.3711 13.2606C6.25785 13.2606 7.10685 13.2606 7.9491 13.2606C7.9491 11.4806 7.9491 9.74455 7.9491 8.00905C7.0661 8.00905 6.23235 8.00905 5.3711 8.00905Z" fill="#625F6E"/>
  </svg>
</template>

<script>
export default {
name: "CompanyIcon"
}
</script>

<style scoped>

</style>