import axios from 'axios'
import '@/store/actions/notification/categories'
import {
  GET_ALL_CATEGORIES,
  CATEGORY_GET,
  GET_ALL_CATEGORIES_ERROR,
  CATEGORIES_REQUEST,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_CATEGORY_SUCCESS,
  ADD_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_ERROR,
  EDIT_CATEGORY_SUCCESS,
  EDIT_CATEGORY_ERROR,
  EDIT_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERROR,
  DELETE_CATEGORY
} from '@/store/actions/notification/categories'

function initialState() {
  return {
    status: '',
    pagination: {},
    categories: [],
    category: {},
    message: '',
    errors: {}
  }
}

const state = initialState()

const getters = {
  allCategories: state => state.categories,
  singleCategory: state => state.category,
  categoryPagination: state => state.pagination
}

const actions = {
  [GET_ALL_CATEGORIES]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get('/admin/notification/categories', {
          params: params
        })
        .then(response => {
          let data = response.data

          commit('GET_ALL_CATEGORIES_SUCCESS', {
            categories: data.data.category,
            pagination: data.paginate
          })

          resolve(response)
        })
        .catch(error => {
          commit('GET_ALL_CATEGORIES_ERROR', { error: error })

          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [CATEGORY_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get(`/admin/notification/categories/${params.id}`)
        .then(response => {
          let data = response.data

          commit('GET_CATEGORY_SUCCESS', {
            category: data.data.category
          })

          resolve(response)
        })
        .catch(error => {
          commit('GET_CATEGORY_ERROR', { error: error })

          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [ADD_CATEGORY]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .post(`/admin/notification/categories`, params)
        .then(response => {
          commit('ADD_CATEGORY_SUCCESS', {
            message: response.data.data.message
          })
          dispatch('ALERT', {
            show: true,
            text: response.data.data.message
          })

          resolve(response)
        })
        .catch(error => {
          dispatch('ALERT', {
            show: true,
            text: error.data.error.message
          })
          if (error.status === 422) {
            commit('ADD_CATEGORY_ERROR', {
              message: error.data.error.message,
              errors: error.data.error.errors
            })
            reject(error)
          }

          if (error.response) {
            commit('ADD_CATEGORY_ERROR', {
              message: error.response.data.message
            })
          }
          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },

  [EDIT_CATEGORY]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .put(`/admin/notification/categories/${params.id}`, params)
        .then(response => {
          commit('EDIT_CATEGORY_SUCCESS', {
            message: response.data.data.message
          })
          dispatch('ALERT', {
            show: true,
            text: response.data.data.message
          })

          resolve(response)
        })
        .catch(error => {
          dispatch('ALERT', {
            show: true,
            text: error.data.error.message
          })
          if (error.status === 422) {
            commit('EDIT_CATEGORY_ERROR', {
              message: error.data.error.message,
              errors: error.data.error.errors
            })
            reject(error)
          }

          commit('EDIT_CATEGORY_ERROR', {
            message: error.response.data.message
          })
          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  },
  [DELETE_CATEGORY]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .delete(`/admin/notification/categories/${params.id}`, params)
        .then(response => {
          commit('DELETE_CATEGORY_SUCCESS', {
            message: response.data.data.message
          })
          dispatch('ALERT', {
            show: true,
            text: response.data.data.message
          })

          resolve(response)
        })
        .catch(error => {
          dispatch('ALERT', {
            show: true,
            text: error.data.error.message
          })
          if (error.status === 422) {
            commit('DELETE_CATEGORY_ERROR', {
              message: error.data.error.message,
              errors: error.data.error.errors
            })
            reject(error)
          }

          commit('DELETE_CATEGORY_ERROR', {
            message: error.response.data.message
          })
          reject(error)
        })
        .finally(() => {
          dispatch('API_PROCESSING', false, { root: true })
        })
    })
  }
}

const mutations = {
  [CATEGORIES_REQUEST]: state => {
    state.status = 'loading'
  },
  [GET_ALL_CATEGORIES_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.categories = params.categories
    state.pagination = params.pagination
  },
  [GET_ALL_CATEGORIES_ERROR]: state => {
    state.status = 'error'
  },
  [GET_CATEGORY_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.category = params.category
  },
  [ADD_CATEGORY_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.message = params.message
  },
  [ADD_CATEGORY_ERROR]: (state, params) => {
    state.status = 'error'
    state.message = params.message
    state.errors = params.errors
  },
  [EDIT_CATEGORY_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.message = params.message
  },
  [EDIT_CATEGORY_ERROR]: (state, params) => {
    state.status = 'error'
    state.message = params.message
    state.errors = params.errors
  },

  [DELETE_CATEGORY_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.message = params.message
  },
  [DELETE_CATEGORY_ERROR]: (state, params) => {
    state.status = 'error'
    state.message = params.message
    state.errors = params.errors
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
