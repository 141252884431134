<template>
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.816832 4.99216C0.816832 4.09841 0.839801 3.20403 0.808707 2.31137C0.788395 1.72966 1.05293 1.36028 1.52605 1.09559C2.11933 0.763874 2.77214 0.605905 3.43511 0.517311C4.8573 0.327155 6.27371 0.353874 7.66293 0.758718C7.94152 0.839968 8.21402 0.956218 8.47168 1.09075C8.96761 1.34981 9.21668 1.73887 9.20714 2.33653C9.17964 4.03934 9.1973 5.74325 9.1973 7.44715C9.1973 8.48997 8.90714 8.88731 7.90089 9.20169C6.74183 9.56356 5.55355 9.67012 4.34574 9.59809C3.57746 9.55231 2.8223 9.43231 2.08808 9.19622C1.10808 8.88059 0.816832 8.48247 0.816832 7.46403C0.816832 6.63966 0.816832 5.81606 0.816832 4.99216ZM1.67949 6.12997C1.67105 6.15903 1.65746 6.18403 1.65746 6.20903C1.65574 6.59965 1.65558 6.99028 1.65558 7.38122C1.65543 8.12591 1.67855 8.17809 2.40402 8.4045C3.74605 8.82387 5.1173 8.862 6.50043 8.66419C6.99793 8.59309 7.48886 8.48512 7.94699 8.27481C8.12589 8.19309 8.35527 8.1195 8.35714 7.85512C8.36105 7.27887 8.35839 6.70278 8.35839 6.12294C6.09839 6.87809 3.88918 6.87825 1.67949 6.12997ZM1.65543 3.18872C1.65543 3.64075 1.65543 4.08294 1.65543 4.52481C1.65543 5.17497 1.68152 5.22778 2.31964 5.44012C3.68496 5.8945 5.08355 5.92934 6.49543 5.73215C6.99324 5.66294 7.4848 5.55387 7.94324 5.34372C8.1223 5.26169 8.35402 5.18934 8.35652 4.92622C8.36199 4.34903 8.35808 3.77169 8.35808 3.18872C6.10277 3.96262 3.8948 3.96153 1.65543 3.18872ZM5.00543 1.24231C4.00793 1.26887 3.0223 1.33684 2.09886 1.75231C1.93761 1.82466 1.69886 1.89559 1.69199 2.07059C1.68339 2.28434 1.94277 2.34075 2.11027 2.42059C2.73605 2.71981 3.41168 2.82762 4.09433 2.87981C5.38293 2.97809 6.65855 2.937 7.87714 2.43372C8.05449 2.36028 8.31418 2.28294 8.31933 2.0995C8.32543 1.87856 8.04918 1.81387 7.86527 1.73278C6.9523 1.33044 5.97949 1.26841 5.00543 1.24231Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
name: "SalesAndCostIcon"
}
</script>

<style scoped>

</style>