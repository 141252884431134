import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import UUID from 'vue-uuid'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/plugins/vee-validate'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import '@/filters/index'
import i18n from '@/plugins/i18n'

require('@/services/api')

Vue.config.productionTip = false

Vue.use(UUID)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.use(CKEditor)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
