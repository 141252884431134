<template><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.3977 3.27241C12.9161 1.33705 10.6632 0.227051 8.21678 0.227051C6.49681 0.227051 4.8658 0.779627 3.49999 1.82512C3.00581 2.20347 2.55874 2.64094 2.16954 3.1238L1.8225 1.15808C1.7845 0.943108 1.57928 0.799338 1.36452 0.837546L0.32656 1.02074C0.223318 1.03897 0.131564 1.09747 0.0714317 1.18332C0.0112992 1.26923 -0.0122057 1.37542 0.00602902 1.47866L0.830599 6.14955C0.864486 6.34128 1.03123 6.4762 1.21938 6.4762C1.2422 6.4762 1.26533 6.47419 1.28858 6.47008L5.95952 5.64551C6.17449 5.60757 6.318 5.40256 6.28005 5.18759L6.09681 4.14963C6.07857 4.04639 6.02013 3.95464 5.93422 3.8945C5.84832 3.83437 5.74213 3.81092 5.63888 3.8291L3.69245 4.17272C3.96624 3.84913 4.27612 3.55395 4.62127 3.28975C5.66276 2.49254 6.90646 2.07119 8.21799 2.07119C10.0846 2.07119 11.8032 2.91773 12.9332 4.39369C13.8958 5.65115 14.3111 7.20828 14.1026 8.77815C13.8941 10.3481 13.0867 11.7428 11.8292 12.7054C10.7847 13.505 9.54297 13.9276 8.23813 13.9276C7.97483 13.9276 7.70784 13.9099 7.4446 13.8749C5.87472 13.6664 4.47997 12.8591 3.51733 11.6015C2.93139 10.8361 2.5376 9.93305 2.3785 8.98985C2.36105 8.88651 2.30324 8.79428 2.21781 8.73351C2.13243 8.67275 2.02629 8.64851 1.92294 8.66584L0.883616 8.84118C0.668383 8.87749 0.523348 9.08145 0.559607 9.29668C0.768463 10.5349 1.28473 11.7196 2.05264 12.7228C3.31474 14.3715 5.14343 15.43 7.2017 15.7034C7.54605 15.7492 7.89551 15.7723 8.24034 15.7723C9.9524 15.7723 11.5811 15.2182 12.9502 14.1701C14.599 12.908 15.6576 11.0793 15.9309 9.021C16.2045 6.96269 15.6599 4.92113 14.3977 3.27241Z" fill="#C4C4C4"/>
</svg>


</template>

<script>
export default {
name: "ClearIcon"
}
</script>

<style scoped>

</style>