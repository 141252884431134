<template>
  <svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
       y="0px" width="64px" height="64px" viewBox="0 0 64 64" enable-background="new 0 0 64 64" xml:space="preserve">
<line fill="none" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-miterlimit="10" x1="528" y1="220.5" x2="584" y2="220.5"/>
    <line fill="none" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-miterlimit="10" x1="530" y1="239.5" x2="543" y2="239.5"/>
    <line fill="none" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-miterlimit="10" x1="536.5" y1="246" x2="536.5" y2="233"/>
    <line fill="none" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-miterlimit="10" x1="566" y1="239.5" x2="579" y2="239.5"/>
    <line fill="none" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-miterlimit="10" x1="555.5" y1="221" x2="555.5" y2="260"/>
    <line fill="none" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-miterlimit="10" x1="4" y1="12.5" x2="60" y2="12.5"/>
    <line fill="none" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-miterlimit="10" x1="6" y1="31.5" x2="19" y2="31.5"/>
    <line fill="none" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-miterlimit="10" x1="12.5" y1="38" x2="12.5" y2="25"/>
    <line fill="none" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-miterlimit="10" x1="42" y1="31.5" x2="55" y2="31.5"/>
    <line fill="none" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-miterlimit="10" x1="31.5" y1="13" x2="31.5" y2="52"/>
</svg>

</template>

<script>
export default {
name: "AccountingIcon"
}
</script>

<style scoped>

</style>