import axios from 'axios'
import {
  MASTER_REQUEST,
  MASTER_ALL_GET,
  MASTER_ALL_GET_SUCCESS,
  MASTER_ALL_GET_ERROR,
  MASTER_PREFECTURES_GET,
  MASTER_PREFECTURES_GET_SUCCESS,
  MASTER_PREFECTURES_GET_ERROR,
  MASTER_COMPANY_TYPES_GET,
  MASTER_COMPANY_TYPES_GET_SUCCESS,
  MASTER_COMPANY_TYPES_GET_ERROR,
  MASTER_HEAD_COUNTS_GET,
  MASTER_HEAD_COUNTS_GET_SUCCESS,
  MASTER_HEAD_COUNTS_GET_ERROR,
  MASTER_SUBSCRIPTION_PLANS_GET,
  MASTER_SUBSCRIPTION_PLANS_GET_SUCCESS,
  MASTER_SUBSCRIPTION_PLANS_GET_ERROR
} from '@/store/actions/master'

function initialState() {
  return {
    status: '',
    prefectures: [],
    company_types: [],
    head_counts: [],
    subscription_plans: [],
    company_statuses: [],
    account_item_types: [],
    account_item_subtypes: [],
    account_item_categories: []
  }
}

const state = initialState()

const getters = {
  getPrefectures: state => state.prefectures,
  getCompanyTypes: state => state.company_types,
  getHeadCounts: state => state.head_counts,
  getSubscriptionPlans: state => state.subscription_plans,
  getCompanyStatuses: state => state.company_statuses,
  getAccountItemTypes: state => state.account_item_types,
  getAccountItemSubtypes: state => state.account_item_subtypes,
  getAccountItemCategories: state => state.account_item_categories
}

const actions = {
  [MASTER_ALL_GET]: ({ commit, dispatch }) => {
    commit('MASTER_REQUEST')

    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get(`/master/all`)
        .then(response => {
          commit('MASTER_ALL_GET_SUCCESS', { ...response.data.data })

          resolve(response)
        })
        .catch(error => {
          commit('MASTER_ALL_GET_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  },

  [MASTER_PREFECTURES_GET]: ({ commit, dispatch }) => {
    commit('MASTER_REQUEST')

    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get(`/master/prefectures`)
        .then(response => {
          commit('MASTER_PREFECTURES_GET_SUCCESS', { ...response.data.data })

          resolve(response)
        })
        .catch(error => {
          commit('MASTER_PREFECTURES_GET_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  },

  [MASTER_HEAD_COUNTS_GET]: ({ commit, dispatch }) => {
    commit('MASTER_REQUEST')

    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get(`/master/head-counts`)
        .then(response => {
          commit('MASTER_HEAD_COUNTS_GET_SUCCESS', { ...response.data.data })

          resolve(response)
        })
        .catch(error => {
          commit('MASTER_HEAD_COUNTS_GET_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  },

  [MASTER_COMPANY_TYPES_GET]: ({ commit, dispatch }) => {
    commit('MASTER_REQUEST')

    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get(`/master/company-types`)
        .then(response => {
          commit('MASTER_COMPANY_TYPES_GET_SUCCESS', { ...response.data.data })

          resolve(response)
        })
        .catch(error => {
          commit('MASTER_COMPANY_TYPES_GET_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  },

  [MASTER_SUBSCRIPTION_PLANS_GET]: ({ commit, dispatch }) => {
    commit('MASTER_REQUEST')

    return new Promise((resolve, reject) => {
      dispatch('API_PROCESSING', true, { root: true })
      axios
        .get(`/master/subscription-plans`)
        .then(response => {
          commit('MASTER_SUBSCRIPTION_PLANS_GET_SUCCESS', {
            ...response.data.data
          })

          resolve(response)
        })
        .catch(error => {
          commit('MASTER_SUBSCRIPTION_PLANS_GET_ERROR')

          reject(error)
        })
        .finally(() => dispatch('API_PROCESSING', false, { root: true }))
    })
  }
}

const mutations = {
  [MASTER_REQUEST]: state => {
    state.status = 'loading'
  },
  [MASTER_ALL_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.prefectures = params.prefectures
    state.subscription_plans = params.subscription_plans
    state.company_types = params.company_types
    state.company_statuses = params.company_statuses
    state.account_item_types = params.account_item_types
    state.account_item_subtypes = params.account_item_subtypes
    state.account_item_categories = params.account_item_categories
    state.head_counts = params.head_counts
  },
  [MASTER_ALL_GET_ERROR]: state => {
    state.status = 'error'
  },
  [MASTER_PREFECTURES_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.prefectures = params.prefectures
  },
  [MASTER_PREFECTURES_GET_ERROR]: state => {
    state.status = 'error'
  },
  [MASTER_SUBSCRIPTION_PLANS_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.subscription_plans = params.subscription_plans
  },
  [MASTER_SUBSCRIPTION_PLANS_GET_ERROR]: state => {
    state.status = 'error'
  },
  [MASTER_COMPANY_TYPES_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.company_types = params.company_types
  },
  [MASTER_COMPANY_TYPES_GET_ERROR]: state => {
    state.status = 'error'
  },
  [MASTER_HEAD_COUNTS_GET_SUCCESS]: (state, params) => {
    state.status = 'success'
    state.head_counts = params.head_counts
  },
  [MASTER_HEAD_COUNTS_GET_ERROR]: state => {
    state.status = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
