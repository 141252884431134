export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES'
export const CATEGORY_GET = 'CATEGORY_GET'
export const ADD_CATEGORY = 'ADD_CATEGORY'
export const CATEGORIES_REQUEST = 'CATEGORIES_REQUEST'

export const GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS'
export const GET_ALL_CATEGORIES_ERROR = 'GET_ALL_CATEGORIES_ERROR'
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS'
export const GET_CATEGORY_ERROR = 'GET_CATEGORY_ERROR'
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS'
export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR'
export const EDIT_CATEGORY = 'EDIT_CATEGORY'
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS'
export const EDIT_CATEGORY_ERROR = 'EDIT_CATEGORY_ERROR'

export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR'
