export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS'
export const NOTIFICATION_GET = 'NOTIFICATION_GET'
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const EDIT_NOTIFICATION = 'EDIT_NOTIFICATION'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST'

export const GET_ALL_NOTIFICATIONS_SUCCESS = 'GET_ALL_NOTIFICATIONS_SUCCESS'
export const GET_ALL_NOTIFICATIONS_ERROR = 'GET_ALL_NOTIFICATIONS_ERROR'
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS'
export const GET_NOTIFICATION_ERROR = 'GET_NOTIFICATION_ERROR'
export const ADD_NOTIFICATION_SUCCESS = 'ADD_NOTIFICATION_SUCCESS'
export const ADD_NOTIFICATION_ERROR = 'ADD_NOTIFICATION_ERROR'
export const EDIT_NOTIFICATION_SUCCESS = 'EDIT_NOTIFICATION_SUCCESS'
export const EDIT_NOTIFICATION_ERROR = 'EDIT_NOTIFICATION_ERROR'
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS'
export const DELETE_NOTIFICATION_ERROR = 'DELETE_NOTIFICATION_ERROR'

export const GET_ALL_NOTIFICATION_TYPES = 'GET_ALL_NOTIFICATION_TYPES'
export const GET_ALL_NOTIFICATION_TYPES_SUCCESS =
  'GET_ALL_NOTIFICATION_TYPES_SUCCESS'
export const GET_ALL_NOTIFICATION_TYPES_ERROR =
  'GET_ALL_NOTIFICATION_TYPES_ERROR'

export const GET_ALL_NOTIFICATION_USER_VIEWS = 'GET_ALL_NOTIFICATION_USER_VIEWS'
export const GET_ALL_NOTIFICATION_USER_VIEWS_SUCCESS =
  'GET_ALL_NOTIFICATION_USER_VIEWS_SUCCESS'
export const GET_ALL_NOTIFICATION_USER_VIEWS_ERROR =
  'GET_ALL_NOTIFICATION_USER_VIEWS_ERROR'
