export const GROUP_ADD_NEW = 'GROUP_ADD_NEW'
export const GROUP_ADD_NEW_SUCCESS = 'GROUP_ADD_NEW_SUCCESS'
export const GROUP_ADD_NEW_ERROR = 'GROUP_ADD_NEW_ERROR'

export const GROUP_GET_ALL = 'GROUP_GET_ALL'
export const GROUP_GET_ALL_SUCCESS = 'GROUP_GET_ALL_SUCCESS'
export const GROUP_GET_ALL_ERROR = 'GROUP_GET_ALL_ERROR'

export const GROUP_DELETE = 'GROUP_DELETE'
export const GROUP_DELETE_SUCCESS = 'GROUP_DELETE_SUCCESS'
export const GROUP_DELETE_ERROR = 'GROUP_DELETE_ERROR'

export const GROUP_EDIT = 'GROUP_EDIT'
export const GROUP_EDIT_SUCCESS = 'GROUP_EDIT_SUCCESS'
export const GROUP_EDIT_ERROR = 'GROUP_EDIT_ERROR'

export const GROUP_GET = 'GROUP_GET'
export const GROUP_GET_SUCCESS = 'GROUP_GET_SUCCESS'
export const GROUP_GET_ERROR = 'GROUP_GET_ERROR'
