import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'

import auth from './modules/auth'
import stats from './modules/stats'
import company from './modules/company'
import master from './modules/master'
import users from './modules/users'
import notifications from './modules/notification/notifications'
import categories from './modules/notification/categories'
import tags from './modules/notification/tags'
import linkposts from './modules/notification/linkposts'
import faq from './modules/faq'
import usergroup from './modules/usergroup'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: true,
    apiProcessing: false,
    alert: {
      show: false,
      text: null
    },
    companies: [],
    deals: [],
    balances: [],
    dashboard: null,
    goals: []
  },
  mutations: {
    toggleDrawer(state) {
      state.drawer = !state.drawer
    },
    setApiProcessing(state, payload) {
      state.apiProcessing = payload
    },
    setAlert(state, payload) {
      state.alert = payload
    },
    setCompanies(state, payload) {
      state.companies = payload
    }
  },
  actions: {
    TOGGLE_DRAWER({ commit }) {
      commit('toggleDrawer')
    },
    API_PROCESSING({ commit }, payload) {
      commit('setApiProcessing', payload)
    },
    ALERT({ commit }, payload) {
      commit('setAlert', payload)
    },
    GET_COMPANY({ commit }, params) {
      commit('setApiProcessing', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/companies', {
            params: { ...params }
          })
          .then(resp => {
            if (resp.status >= 200 && resp.status <= 299) {
              commit('setApiProcessing', false)
              commit('setCompanies', resp.data)
              resolve()
            }
          })
          .catch(err => {
            commit('setApiProcessing', false)
            reject(err)
          })
      })
    },
    SYNC_COMPANY({ commit }) {
      commit('setApiProcessing', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/company/sync')
          .then(resp => {
            if (resp.data.success) {
              commit('setApiProcessing', false)
              commit('setCompanies', resp.data.data)
              resolve()
            }
          })
          .catch(err => {
            commit('setApiProcessing', false)
            reject(err)
          })
      })
    },
    EDIT_COMPANY({ commit }, { id, payload }) {
      commit('setApiProcessing', true)
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/company/${id}`, payload)
          .then(resp => {
            if (resp.data.success) {
              commit('setApiProcessing', false)
              resolve()
            }
          })
          .catch(err => {
            commit('setApiProcessing', false)
            reject(err)
          })
      })
    }
  },
  getters: {
    DRAWER_STATE(state) {
      return state.drawer
    },
    getCompanies: state => {
      return state.companies.data.companies
    },
    getCompaniesPagination: state => {
      return state.companies.paginate
    }
  },
  modules: {
    auth,
    stats,
    company,
    master,
    users,
    notifications,
    tags,
    categories,
    linkposts,
    faq,
    usergroup
  },
  plugins: [createPersistedState()]
})
